.signInOTPVerification-inputHeader {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  font-family: var(--font-family);
}

.signInOTPVerification-input {
  margin-top: 20px;
  margin: 20px auto 0 auto;
  width: fit-content;
}

.signInOTPVerification-resend {
  display: flex;
  gap: 11px;
  justify-content: end;
  font-size: 14px;
  margin-top: 7px;
}

.signInOTPVerification-resentBtn {
  font-weight: 500;
  text-decoration: underline;
  color: #000000;
}

.signInOTPVerification-resentBtn.disabled {
  color: #787878;
  cursor: default;
}

.signInOTPVerification-btn {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.signInOTPVerification-verify {
  border-radius: 6px;
  text-align: center;
  padding: 10px;
  width: 260px;
  font-size: 12px;
  font-family: var(--font-family);
}

@media (max-width: 480px) {
  .signInOTPVerification-inputHeader img {
    margin-left: 10px;
  }
}
