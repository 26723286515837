.gstVerifcation-inputText {
  font-size: 10px;
  position: relative;
  font-weight: 500;
  top: 8px;
  left: 10px;
  padding: 0px 5px;
  z-index: 10;
  background-color: white;
  width: fit-content;
  font-family: var(--font-family);
}

.gstVerification-inputHeader > input {
  width: 100%;
  border: 1px solid #72777a;
  border-radius: 6px;
  font-size: 12px;
  padding: 10px;
  font-family: var(--font-family);
}

.gstVerification-inputHeader > div[name='bussinessAddress'] {
  width: 100%;
  min-height: 53px;
  height: auto;
  border: 1px solid #72777a;
  border-radius: 6px;
  font-size: 12px;
  padding: 10px;
  color: #72777a;
  background-color: transparent;
}

.gstVerification-inputHeader > input:disabled,
.gstVerification-inputHeader > div[name='bussinessAddress']:disabled,
.gstVerification-inputHeader > div[name='bussinessAddress'][disabled] {
  color: #72777a;
  background-color: transparent;
  border-color: #72777a;
}

.gstVerification-btn {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.gstVerification-submit {
  border-radius: 6px;
  text-align: center;
  padding: 10px;
  width: 260px;
  font-size: 12px;
  font-family: var(--font-family);
}
