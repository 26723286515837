.exclusive {
  background-color: var(--wine);
  color: var(--white);
  font-size: 13px;
  min-height: 30px;
  font-family: var(--font-family);
  font-weight: 400;
  max-height: 40px;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  line-height: 11px;
}

.exclusive a {
  color: var(--white);
}

.exclusive span {
  color: var(--white) !important;
  min-width: 1%;
  min-height: 1px;
  font-size: 10px !important;
}

.exclusiveInner {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 30px;
}

.exclusiveInner > * {
  position: absolute;
  white-space: nowrap;
  animation: slide-infinite 10s linear infinite;
  margin-top: 10px;
}

@keyframes slide-infinite {
  0% {
    transform: translateX(800%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.header-con {
  max-width: 1440px;
}

.m-exclusive {
  background-color: #efefef;
  color: var(--dark);
  font-size: 14px;
  font-family: var(--font-family);
  font-weight: 400;
}

.m-exclusive a {
  color: var(--dark);
}

.getAppCta {
  display: flex;
  white-space: nowrap;
  align-items: center;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 14px;
  gap: 2px;
}

.getAppCta.underline {
  text-decoration: underline;
  text-transform: lowercase;
}

.level-top a,
.level-top a:hover,
.level-top a:focus {
  color: var(--dark);
}

.header-left ul li {
  font-weight: 600;
  font-family: var(--font-family);
  text-transform: uppercase;
  color: var(--dark);
  font-size: 14px;
}

.header-left {
  flex: 1;
  justify-content: center;
}

.header-right {
  justify-content: flex-end;
}

.header-content {
  position: relative;
  padding: 0;
  height: 100%;
}

.search-button {
  position: absolute;
  top: 2px;
  right: 0;
}

.click-submenu {
  position: fixed;
  top: 94px;
  z-index: 99;
  width: 100%;
  background: var(--white);
}

.cat-clr {
  color: transparent;
}

.cat-clr-b {
  color: var(--dark) !important;
}

.sticky-top {
  border-bottom: 1px solid #d9d9d9;
}

.m-header.sticky-top {
  border-bottom: 0;
}

.fullwidthtop .submenu ul li {
  padding: 8px 0;
  font-weight: 400;
}

.fullwidthtop .smul li {
  display: block;
  color: #7a7a7a;
  font-family: var(--font-family), sans-serif;
  font-size: 12px;
  padding: 8px 0;
  margin: 0;
  line-height: 18px;
  letter-spacing: 0.33px;
  border-bottom: 0;
}

.fullwidthtop:hover::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100vw;
  z-index: 20;
  pointer-events: none;
}

.fullwidthtop:hover > .submenu {
  background: var(--white);
  border: 1px solid var(--white);
  box-shadow: 0px 7px 11px 0px rgba(0, 0, 0, 0.25);
  color: var(--dark);
  display: flex;
  flex-direction: column;
  gap: 30px;
  left: 50%;
  max-height: 419px;
  max-width: 1300px;
  margin: 32px 0px;
  overflow: hidden;
  overflow-y: auto;
  position: fixed;
  width: 100%;
  z-index: 9999;
  scrollbar-width: thin;
  scrollbar-color: rgb(151, 149, 149) var(--white);
}

.fullwidthtop:hover > .brandsubmenu {
  top: 100px;
}

.highlited {
  background: #d9d9d9;
}

.header-left ul li:last-child::after {
  display: none;
  content: '';
}

.header-left ul a {
  text-decoration: none;
  color: var(--dark);
}

.header-center {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
}

.header-center::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.header-center::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: #f5f5f5;
}

.header-center::-webkit-scrollbar-thumb {
  background-color: var(--dark);
  border: 2px solid #555555;
}

.brand {
  padding: 5px 0;
}

.brand .brandLogo {
  display: block;
  object-fit: contain;
  width: 229px;
  height: 25px;
}

.brand .b2b_brandLogo {
  height: 41px;
  display: block;
  object-fit: contain;
  width: 229px;
}

.header-row > div {
  display: flex;
  align-items: center;
  width: 0%;
}

.header-right .h-icon {
  padding: 0 12px;
}

.header-right .h-icon > * {
  cursor: pointer;
}

.search {
  clear: both;
}

.search-input {
  position: relative;
  width: calc(100% - 24px);
  height: 35px;
  padding: 6px;
  clear: both;
  border: none;
  font-family: var(--font-family);
  margin-bottom: 7px;
  font-size: 10px;
  background-color: transparent;
  z-index: 9;
  transition: width 10s ease;
  outline: none;
  font-weight: 400;
}

.search {
  position: absolute;
  top: -15px;
  right: 11px;
  width: 276px;
  height: 32px;
  background-color: var(--white);
  z-index: 1020;
  padding: 0;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.disable-scroll .search {
  z-index: unset !important;
}

.search-suggestions {
  position: absolute;
  isolation: isolate;
  top: 18px;
  left: -284.6px;
  right: 10px;
  background-color: var(--white);
  border-radius: 3px;
  z-index: 9998;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.empty-suggestions {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.empty-suggestions svg {
  opacity: 0.1;
}

.empty-suggestions h5 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: unset;
}

.match-key {
  font-size: 12px;
  font-family: var(--font-family);
  padding: 9px 20px;
  background-color: #f2f2f2;
  line-height: 24px;
  display: block;
}

.match-keyword {
  font-size: 12px;
}

.search-suggestions span {
  color: #686767;
  font-weight: 300;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.placeholder-container {
  position: relative;
  font-family: var(--font-family);
  color: #686767;
  z-index: 1;
}

.wrapper {
  font-weight: 300 !important;
  font-size: 8px;
  line-height: 12px;
  margin-left: 10px;
  box-sizing: content-box;
  background-color: var(--white);
  height: 20px;
  display: flex;
  margin-top: -39px;
  z-index: 2;
  contain: content;
}

.wrapper p {
  color: #686767;
  font-weight: 400;
  text-transform: capitalize;
  font-family: var(--font-family);
  line-height: 24px;
  margin: 0;
  margin-right: -5px;
  font-size: 10px;
  margin-top: 0.7px;
  white-space: nowrap;
}

.search-for-words {
  margin: 3px 0 0 0;
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 20px;
  display: block;
  height: 100%;
  padding-left: 8px;
  color: #686767;
  animation: spin_words 7s infinite ease-in;
  white-space: nowrap;
}

@keyframes spin_words {
  0% {
    transform: translateY(100%);
    opacity: 0.5;
  }

  1%,
  2%,
  3%,
  4%,
  5%,
  6%,
  7%,
  8%,
  9%,
  10% {
    opacity: 0.5;
  }

  11%,
  12%,
  13%,
  14%,
  15%,
  16%,
  17%,
  18%,
  19%,
  20%,
  21%,
  22%,
  23%,
  24%,
  25%,
  26% {
    opacity: 1;
    transform: translateY(-8%);
  }

  27%,
  28%,
  29% {
    opacity: 0.5;
  }

  30% {
    opacity: 0.4;
  }

  31% {
    opacity: 0.3;
  }

  32% {
    opacity: 0.2;
  }

  33% {
    opacity: 0.1;
  }

  34%,
  35% {
    opacity: 0;
  }

  36% {
    opacity: 0.4;
  }

  37%,
  38%,
  39% {
    opacity: 0.5;
  }

  40%,
  41%,
  42%,
  43%,
  44%,
  45%,
  46%,
  47%,
  48%,
  49%,
  50%,
  51%,
  52%,
  53%,
  54%,
  55%,
  56% {
    opacity: 1;
    transform: translateY(-210%);
  }

  57%,
  58%,
  59%,
  60%,
  61%,
  62% {
    opacity: 0.5;
  }

  63% {
    opacity: 0.4;
  }

  64% {
    opacity: 0.3;
  }

  65% {
    opacity: 0.2;
  }

  66% {
    opacity: 0.1;
  }

  67%,
  68%,
  69% {
    opacity: 0;
  }

  70% {
    opacity: 0.4;
  }

  71%,
  72%,
  73% {
    opacity: 0.5;
  }

  74% {
    opacity: 1;
  }

  75%,
  76%,
  77%,
  78%,
  79%,
  80%,
  81%,
  82%,
  83%,
  84%,
  85%,
  86%,
  87%,
  88% {
    opacity: 1;
    transform: translateY(-410%);
  }

  89% {
    opacity: 0.5;
  }

  90%,
  91%,
  92%,
  93% {
    opacity: 0.5;
  }

  94%,
  95% {
    opacity: 0.3;
  }

  96% {
    opacity: 0.2;
  }

  97% {
    opacity: 0.1;
  }

  98%,
  99% {
    opacity: 0;
  }

  100% {
    transform: translateY(-500%);
    opacity: 0;
  }
}

.search input:-webkit-autofill,
.search input:-webkit-autofill:hover,
.search input:-webkit-autofill:focus,
.search input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.searchbtn {
  position: relative;
  z-index: 9;
}

.placeholderClass .searchbtn .searchicon {
  top: -36px !important;
}

.searchbtn .searchicon {
  top: -68px !important;
  left: 20px !important;
  color: #606669 !important;
  position: absolute;
  z-index: 1;
  height: 40px;
  width: 25px;
  display: inline-block;
  background: none;
  border: 0;
  box-shadow: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  line-height: 40px;
}

.closeBtn {
  position: relative;
  z-index: 9;
}

.placeholderClass .closeBtn button {
  top: -21px !important;
}

.closeBtn button {
  top: -51px !important;
  right: 5px !important;
  color: #606669 !important;
  position: absolute;
  z-index: 1;
  background: none;
  border: 0;
  box-shadow: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  line-height: 20px;
  background-color: var(--white);
}

.search-icon-img {
  width: 30px;
  height: 13px;
  background-color: var(--white);
}

.searchicon::before {
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  line-height: inherit;
  margin: 0;
  vertical-align: top;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  text-align: center;
}

.headicon {
  width: 32px;
  height: 32px;
}

.shop-icon-chip-h {
  position: absolute;
  display: grid;
  font-family: var(--font-family);
  font-size: 7px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  place-content: center;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  padding: 8px;
  background: var(--wine);
  color: var(--white);
  top: 0%;
  left: 5px;
}

.h-icon {
  position: relative;
}

.dropdown-toggle::after {
  display: none !important;
}

/* Popover */
.nvbrdrp {
  padding: 0 !important;
  z-index: 1030 !important;
  right: -70px;
  position: absolute;
}

.nvbrdrp .dropdown-item {
  width: 181px;
  padding: 15px;
  text-align: left;
  font-family: var(--font-family);
  font-size: 14px;
  text-transform: none;
  background: var(--dark);
  color: var(--white);
  border: 10px solid #ededed;
}

.myacc {
  font-size: 18px !important;
  font-weight: 700;
  background-color: #d9d9d9 !important;
  box-shadow: 0px 4px 5px 0.1px rgba(0, 0, 0, 0.3);
}

.myacc:hover {
  color: var(--dark);
  background-color: #d9d9d9 !important;
}

.nvbrdrp .dropdown-item:hover {
  color: var(--white);
  background-color: var(--dark);
}

.nvbrdrp .dropdown-divider {
  margin: 0;
  border: 0;
}

.nvbr .dropdown-menu[data-bs-popper] {
  top: 55px;
  left: -80px;
  width: fit-content;
  padding: 20px;
  box-shadow: 0px 4px 5px 0.1px rgb(0 0 0 / 30%);
}

.nvbrdrp {
  position: relative;
}

.nvbrdrp::before {
  content: '';
  position: absolute;
  top: -17px;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 17px solid #d9d9d9;
}

.signout {
  list-style: none;
  width: 181px;
}

.signout a {
  text-decoration: none;
}

.signout-li {
  border: none !important;
  margin: 7px 0;
}

.signout-li:first-child,
.signout-li:last-child {
  margin: 0;
}

.signout-li:last-child {
  border-radius: 0px 0px 4px 4px;
}

.signout-img {
  width: 13px;
  height: 12px;
}

.sign-in-tag {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-in-tag a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 3px;
  text-transform: none !important;
}

.signout-cont {
  font-size: 15px;
  padding-left: 2px;
  font-weight: 400;
}

/* Popover */

.icon-wrapper::before {
  content: '';
  position: absolute;
  border: 1px solid white;
  top: 10px;
  left: 17px;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  background-color: var(--wine);
  border-radius: 50%;
}

.mobile-icon-wrapper::before {
  content: '';
  position: absolute;
  border: 1px solid white;
  top: 10px;
  left: 8px;
  transform: translate(-50%, -50%);
  width: 9px;
  height: 9px;
  background-color: var(--wine);
  border-radius: 50%;
}

.hed {
  border: 0 !important;
  border-radius: 4px !important;
  font-family: var(--font-family);
}

.pop-head {
  text-align: justify;
  width: 180px;
}

.popover {
  z-index: 1019;
}

.hed .popover-body {
  padding: 0px;
  width: 95%;
}

.hed .pop-signout-li {
  margin: 12px !important;
}

.hed .pop-head {
  padding: 0px;
  background-color: var(--white);
  box-shadow: 1px 3px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: fit-content !important;
  align-items: center;
  justify-content: center;
  height: fit-content !important;
}

.hed .pop-signout-li {
  list-style: none;
  width: fit-content;
  height: fit-content;
}

.hed .list-group-item {
  margin: 0;
}

.hed li:has(.list-group-item-action):active {
  background: var(--dark);
  color: var(--white);
}

.nvbr .txtbld {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 16px;
  padding: 11px 26px 3px 16px !important;
}

.strUsername {
  font-size: 11px;
  line-height: 6px;
  font-weight: 300;
  color: var(--wine);
}

.usname,
.usrname {
  color: var(--wine);
  font-family: var(--font-family);
  font-size: 8px;
  line-height: 7px;
  overflow: hidden;
  padding: 5px 0;
  position: absolute;
  text-align: center;
  margin-left: -10px;
  width: 100%;
  text-overflow: ellipsis;
}

.usname {
  left: -15px;
  top: 28px;
  width: 49px;
  z-index: 99;
}

.login-text {
  padding-right: 2px;
}

.user-icon {
  position: relative;
}

.hed-sign-in-popup {
  left: -43 !important;
}

.hed {
  left: -50px !important;
  display: flex;
  position: absolute;
  z-index: 9999;
  left: 28%;
  top: 46px;
  transform: translateX(-30%);
  padding: 0 !important;
  background-color: transparent;
}

.hed-sign-in-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 65px;
}

.nvbr .list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  word-wrap: break-word;
}

.m-nvbr .list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  word-wrap: break-word;
  width: 142px;
  white-space: break-spaces;
}

.m-nvbr .txtbld {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 600;
}

.pw {
  position: absolute;
  left: 0px;
  transform: translate3d(82.4px, 0px, 0px);
}

.pw {
  position: relative;
  padding: 0px;
  background: var(--white);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.pw:before {
  content: '';
  position: absolute;
  rotate: 180deg;
  top: -15px;
  left: 0%;
  border-style: solid;
  border-width: 15px 15px 0;
  border-color: var(--dark) transparent;
  display: block;
  width: 0;
  z-index: 0;
}

.pop-over {
  display: flex;
  justify-content: center;
  align-items: center !important;
  position: relative;
  background-color: rgb(233, 212, 222);
  border-radius: 4px;
  top: 50px;
  box-shadow: 3px -2px 4px #aaa;
  border: 0;
}

.pop-over-arrow {
  position: absolute;
  top: -68px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 18px solid var(--white);
  width: 0;
  height: 0;
  filter: drop-shadow(0 -2px 2px #aaa);
}

.pop-over-arrow-cursor-position {
  position: absolute;
  top: -76.5px;
  width: 60px;
  height: 50px;
}

/*********************************************** Sticky Header Hot Fix ********************************************/

.ctnir {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-content: baseline;
  background-color: var(--white);
  border-radius: 3px;
  width: 100%;
  padding: 20px 30px;
}

.griditem {
  display: flex;
  flex-direction: column;
}

.item {
  box-sizing: border-box;
  height: auto;
  padding: 15px;
  position: relative;
}

.subcat {
  cursor: pointer;
  font-family: var(--font-family), sans-serif;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.subcat:hover {
  color: var(--wine);
}

.brand-subcat {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family), sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  height: 40px;
}

.brand-subcat.selected {
  border-bottom: 2px solid #77353b;
}

.brand-allsubcat {
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  padding: 15px 0px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  font-weight: 400;
}

.brandImgList {
  display: grid;
  grid-template-columns: repeat(auto-fill, 167px);
  grid-column-gap: 14px;
  grid-row-gap: 20px;
  justify-content: center;
}

.brandImg {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  height: 80px;
}

.brandImg > img {
  max-height: 55px;
  width: 118px;
  object-fit: contain;
}

.signout-li svg {
  margin-right: 2px;
}

/*********************************************** Sticky Header Hot Fix ********************************************/

@media screen and (max-width: 1280px) {
  .header-content,
  .exclusive {
    padding: 0 20px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .header-left ul li {
    font-size: 14px;
  }

  .searchicon::before {
    font-size: 14px;
  }

  .brand img {
    height: 40px;
    width: 245px;
    margin-right: -5px;
  }

  .headicon {
    width: 25px;
  }
}

@media (min-width: 991px) {
  .m-header {
    display: none;
  }

  .navList {
    margin-bottom: -25px;
  }
}

@media (max-width: 991px) {
  @keyframes slide-infinite {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  .exclusiveInner > * {
    animation-duration: 4s;
  }

  .m-search-input {
    width: 94.5% !important;
  }

  .placeholderClassMobile .m-searchbtn .m-searchicon {
    top: -32px !important;
  }

  .placeholderClassMobile .m-search-outline {
    height: 48px;
  }

  .wrapper p {
    margin-top: -3px;
  }

  .wrapper {
    margin-left: 47px;
    margin-top: -33px;
  }

  .getAppCta {
    font-size: 11px;
    top: 10px;
    right: 24px;
  }

  .m-header {
    display: block;
    margin-bottom: 77px;
  }

  .m-header-margin {
    margin-bottom: 0px;
  }

  .cart-m-header {
    margin-bottom: 60px;
  }

  .m-header-main {
    display: block;
    -webkit-transform: translate3d(0, 0, 0);
    width: 100%;
    top: 0;
    box-shadow: 0 1px 0 0 rgb(0 0 0 / 6%);
    background-color: var(--white);
    z-index: 9;
  }

  .exclusive {
    padding: 0 20px;
  }

  .m-header-content {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .m-header-row > div {
    display: flex;
    align-items: center;
    height: 40px;
  }

  .m-header-left {
    flex: 0 1;
    padding-top: 0;
    padding-bottom: 0;
    min-width: auto;
    margin-right: auto;
    justify-content: flex-start;
  }

  .m-header-center {
    margin: 0 auto;
    justify-content: center;
    padding: 5px 0;
    flex: 1 1;
  }

  .m-brand img {
    display: block;
    height: 30px;
    width: 100%;
    object-fit: revert;
  }

  .m-header-right {
    flex: 0 1;
    padding-top: 0;
    padding-bottom: 0;
    min-width: auto;
    margin-left: 0;
    justify-content: flex-end;
  }

  .m-header-right .navbar-nav {
    flex-direction: row;
    width: 72px;
  }

  .m-h-icon {
    padding: 0 4px;
    position: relative;
  }

  .m-headicon {
    width: 18px;
    height: 17px;
  }

  .m-shop-icon-chip-h {
    position: absolute;
    display: grid;
    font-family: var(--font-family);
    font-size: 10px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    place-content: center;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    padding: 5px;
    background: var(--wine);
    color: var(--white);
    top: -5%;
    left: 0px;
  }
}

@media screen and (min-width: 320px) and (max-width: 990px) {
  .signout-cont {
    font-size: 14px;
  }

  .m-nvbr .list-group-item {
    width: 145px;
  }

  .m-nvbr .txtbld {
    font-size: 16px;
  }

  .hed .list-group-item {
    border: 0;
  }

  .signout {
    width: 142px;
  }

  .m-nvbr {
    padding: 0 0 0 15px !important;
  }

  .pop-user-name {
    margin-left: 3px;
    padding: 2px 0;
  }

  .hed {
    border: 0 !important;
    border-radius: 4px !important;
    font-family: var(--font-family);
  }

  .pop-head {
    text-align: justify;
    width: 180px;
  }

  .pop-head .dropdown-item:hover {
    color: var(--white);
  }

  .m-nvbr .txtbld {
    font-size: 15px;
  }

  .hed .popover-body {
    padding: 0px;
    width: 95%;
  }

  .hed .pop-signout-li {
    margin: 12px !important;
  }

  .hed .pop-head {
    padding: 0px;
    background-color: var(--white);
    box-shadow: 1px 3px 6px 1px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: fit-content;
  }

  .hed .pop-signout-li {
    padding: 12px 14px;
  }

  .hed {
    position: absolute;
    z-index: 9999;
    left: 0 !important;
    top: 54px;
    transform: translateX(-30%);
    width: 250px;
    padding: 0 !important;
    background-color: transparent;
  }

  .pop-over {
    position: relative;
    background-color: rgb(233, 212, 222);
    border-radius: 4px;
    top: 50px;
    left: 50px;
    box-shadow: 3px -2px 4px #aaa;
    border: 0;
  }

  .pop-over-arrow {
    position: absolute;
    top: -71.5px;
    left: 21px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid var(--white);
    width: 0;
    height: 0;
    filter: drop-shadow(0 -2px 2px #aaa);
  }
}

@media screen and (max-width: 768px) {
  .signout-cont {
    font-size: 14px;
  }

  .signout-li svg {
    margin-right: 8px;
  }

  .skeleton-header-mob {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .m-brand img {
    height: 23px !important;
    width: 150px !important;
  }

  .m-exclusive {
    font-size: 12px;
  }
}

@media screen and (max-width: 426px) {
  .m-exclusive {
    font-size: 10px;
  }
}

@media screen and (max-width: 376px) {
  .m-brand img {
    width: 130px !important;
  }

  .m-nvbr {
    padding: 0px 5px 0 8px !important;
  }

  .m-exclusive {
    font-size: 10px;
  }

  .getAppCta {
    right: 10px;
  }
}

@media screen and (max-width: 320px) {
  .m-exclusive {
    font-size: 8px;
  }
}

@media screen and (min-width: 411px) {
  .m-brand img {
    height: 35px;
  }
}

@media screen and (min-width: 320px) {
  .m-brand img {
    height: 25px;
    width: 130px;
  }
}

.h-icon {
  position: relative;
}

.dropdown-toggle::after {
  display: none !important;
}

/* Mobile Header */
.burgermenu {
  width: 24px;
  height: 18px;
  cursor: pointer;
}

.m-search-outline {
  border: 1px solid #cccccc;
  border-radius: 5px;
}

.m-search-outline-black {
  border: 1px solid var(--dark);
  border-radius: 5px;
  height: 48px;
}

.m-search {
  font-family: var(--font-family);
  position: fixed;
  width: 100%;
  left: 0;
  box-shadow: 0 1px 0 0 rgb(0 0 0 / 6%);
  background-color: var(--white);
  padding: 10px 16px 10px;
  z-index: 2;
}

.m-search .placeholder-container span {
  top: -36px;
  left: 46px;
}

.m-search .placeholder-container .placeholder-text {
  left: 134px;
}

/* clears the ‘X’ from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.m-search-input {
  outline: none;
  width: 100%;
  background-color: transparent;
  z-index: 2;
  border-radius: 5px;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  padding: 11px 20px 11px 44px;
  position: relative;
}

.m-search input {
  caret-color: var(--dark) !important;
  outline: none !important;
  border: none;
  font-family: var(--font-family);
  color: var(--dark);
  z-index: 9;
  background-color: transparent;
}

.search input {
  caret-color: #656262;
  position: relative;
  bottom: 2px;
}

.m-search .search-suggestions .searchUl {
  max-height: 62vh;
  overflow-y: scroll;
}

.m-searchbtn {
  position: relative;
  z-index: 2;
}

.m-searchbtn .m-searchicon {
  top: -44px !important;
  left: 15px !important;
  color: #606669 !important;
  position: absolute;
  z-index: 1;
  height: 40px;
  width: 25px;
  display: inline-block;
  background-image: none;
  background-color: var(--white);
  border: 0;
  box-shadow: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  line-height: 40px;
}

.m-closeBtn button {
  top: -44px !important;
  right: 10px !important;
  z-index: 2;
}

.m-search-icon-img {
  width: 24px;
}

.m-side-close {
  cursor: pointer;
  width: 23px;
}

.m-navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
}

.m-nvbr .dropdown-menu[data-bs-popper] {
  top: 40px;
  left: -50px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 20px;
  box-shadow: 0px 4px 5px 0.1px rgb(0 0 0 / 30%);
}

.m-nvbrdrp {
  padding: 0 !important;
  z-index: 1030 !important;
  left: 0;
  position: absolute;
}

.m-nvbrdrp::before {
  content: '';
  position: absolute;
  top: -17px;
  left: 25%;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 17px solid #d9d9d9;
}

.m-nvbrdrp .dropdown-item {
  width: 181px;
  padding: 15px;
  text-align: left;
  font-family: var(--font-family);
  font-size: 14px;
  background: var(--dark);
  color: var(--white);
  border: 10px solid #ededed;
  text-transform: none;
}

.m-nvbrdrp .dropdown-item:hover {
  color: var(--dark);
  background-color: transparent;
}

.m-myacc {
  font-size: 18px !important;
  font-weight: 700;
  background-color: #d9d9d9 !important;
  box-shadow: 0px 4px 5px 0.1px rgb(0 0 0 / 30%);
}

.m-myacc:hover {
  color: var(--dark);
  background-color: #d9d9d9 !important;
}

.nf-img {
  width: 100%;
}

.search-suggestions-wrapper {
  position: relative;
  padding-top: 0;
  font-family: var(--font-family);
}

.searchBackdrop {
  width: 100%;
  height: 84vh;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
}

.search-suggestions .searchUl {
  display: block;
  padding: 0px;
  margin-bottom: 0;
  height: auto;
  max-height: 576px;
  overflow-y: hidden;
  border-radius: 3px;
}

.search-suggestions .searchUl::-webkit-scrollbar {
  width: 2px;
}

.search-suggestions .searchUl::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

.search-suggestions .searchUl::-webkit-scrollbar-thumb {
  background-color: slategrey;
  outline: 1px solid slategrey;
}

.search-suggestions .searchUl li.search-item {
  all: unset;
  display: block;
  cursor: pointer;
  text-transform: uppercase;
  padding: 10px 20px !important;
  font-size: 12px;
  font-weight: 300;
  color: var(--dark);
  list-style: none;
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  padding-left: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 28px;
}

.search-suggestions .searchUl li.search-item strong {
  font-weight: 500;
}

.search-suggestions .searchUl li.search-item:hover {
  background: #d9d9d9;
}

.search-suggestions .searchUl li.highlighted {
  background: #d9d9d9;
}

.search-suggestions .searchUl li.search-item::after {
  content: '';
  width: 20px;
  height: 20px;
  background: var(--dark);
}

.search-suggestions .searchUl li.search-item::before {
  content: '';
  width: 20px;
  height: 20px;
  background: var(--dark);
}

.nav-head {
  background-color: var(--white);
  height: 64px;
}

.navbar-nav li {
  margin: 0 !important;
}

@media (max-width: 1024px) {
  .search {
    width: 300px;
  }

  .search-suggestions {
    max-width: 300px;
    left: -255px;
  }

  .search-input {
    width: 300px;
  }
}

@media (max-width: 992px) {
  .search-for-words {
    line-height: 1rem;
  }

  .m-closeBtn button {
    top: -37px !important;
  }

  .header {
    display: none;
  }

  .searchBackdrop {
    height: auto;
    left: 0;
    top: 68px;
  }

  .m-search-suggestions {
    width: 100%;
  }

  .search-suggestions {
    width: 100%;
    height: auto;
    left: 0;
    top: 67px;
    max-width: 100%;
  }

  .empty-suggestions {
    height: 200px;
  }
}

.cartDropdown {
  position: absolute;
  z-index: 9999;
  top: 47px;
  transform: translateX(-30%);
  background-color: var(--white);
  width: 400px;
  right: -133px;
  padding: 20px 20px 20px 14px;
  box-shadow: 1px 3px 6px 1px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.wishlistDropDown {
  right: -178px !important;
}

.cart-pop-over {
  position: relative;
  box-shadow: 1px 3px 6px 1px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  box-shadow: 3px -2px 4px #aaa;
  border: 0;
}

.cart-pop-over-arrow {
  position: absolute;
  left: 334px;
  top: -24px;
  border: 2px solid #77353b;
  border-radius: 3px;
  width: 32px;
  height: 0;
  filter: none;
}

.wishlistDropDown .cart-pop-over-arrow {
  position: absolute;
  top: -24px;
  border: 2px solid #77353b;
  border-radius: 3px;
  width: 32px;
  height: 0;
  filter: none;
}

.wishlist-pop-over-arrow {
  left: 288px !important;
}

.wish-mini {
  max-height: 50px;
}

.cart-pop-over-arrow-select-aria {
  position: absolute;
  top: -42px;
  left: 285px;
  height: 22px;
  width: 85px;
}

.cartDropdown h3 {
  font-size: 14px;
  text-transform: uppercase;
  border-bottom: 1px solid #787878;
  margin-bottom: 10px;
  padding-bottom: 10px;
  margin-left: 6px;
}

.cartDropdown .cartDropdownProductWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 360px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding-left: 6px;
}

.cartDropdown .cartDropdownProductWrapper::-webkit-scrollbar {
  width: 0.1em;
}

.cartDropdown .cartDropdownProductWrapper::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.brand-name-wishlist-drpdwn {
  margin-top: -17px;
}

.brand-name-wishlist-drpdwn-conf {
  margin-top: -15px;
}

.dropdownCartProd {
  position: relative;
  background: #f5f5f5;
  display: flex;
  padding: 10px;
  flex-direction: column;
  line-height: 17px;
  border-radius: 3px;
}

.dropdownCartProd .wish-pop {
  line-height: 0.7rem;
  margin-top: 0;
}

.dropfreeProduct {
  position: absolute;
  top: 0;
  left: 0;
}

.dropdownCartProd .dropfreeProduct .free-p {
  top: 2px;
  left: -6px;
  display: inherit;
  width: 33px;
  height: 12px;
  writing-mode: vertical-rl;
  -webkit-writing-mode: horizontal-tb;
  transform: rotate(0deg);
  white-space: nowrap;
  position: absolute;
  color: #f9f9f9;
  line-height: 8px !important;
  background-color: #ea6cd6;
  font-weight: 700;
  margin: 0;
  font-size: 9px !important;
  text-transform: uppercase;
  padding: 2px 6px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
}

.free-lab {
  position: absolute;
  background-color: var(--white);
  top: 14px;
  left: -6px;
  width: 6px;
  height: 6px;
  border-right: 7px solid #bb3fa7;
  border-bottom: 7px solid var(--white);
}

.dropdownWishlistProd {
  position: relative;
  background: #f5f5f5;
  display: flex;
  padding: 10px;
  flex-direction: column;
  line-height: 12px;
  gap: 10px;
  border-radius: 3px;
}

.dropdownWishlistProd .bottomWrapper {
  text-align: end;
  text-transform: capitalize;
  width: 100%;
  position: absolute;
  right: 10px;
}

.dropdownWishlistProd .singshade {
  font-size: 9px;
  font-weight: 300;
}

.dropdownWishlistProd .bottomWrapper button {
  background-color: transparent;
  border: none;
  color: var(--dark);
  font-family: var(--font-family);
  font-size: 9px;
  font-style: normal;
  font-weight: 300;
  text-transform: capitalize;
  line-height: 14px;
}

.dropdownWishlistProd .bottomWrapper button:hover {
  text-decoration: underline;
}

.dropdownWishlistProd .rPrice {
  color: #787878;
  font-size: 10px;
  font-family: var(--font-family);
  font-weight: 400;
  position: relative;
  line-height: 16px;
}

.dropdownWishlistProd .dis {
  color: #77353b;
  font-size: 12px;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 300;
  padding-top: 1px;
}

.mini-wishdrop-prod {
  display: flex;
  gap: 10px;
}

.wishdrop-price {
  display: flex;
  gap: 11px;
  min-height: 20px;
  font-family: var(--font-family);
}

.strike-dis {
  color: #b6b6b6;
  position: relative;
  text-decoration: line-through;
}

.wishdrop-fp {
  font-weight: 600;
  font-size: 12px;
  padding-top: 1.2px;
}

.wishdrop-rp {
  font-weight: 400;
  font-size: 12px;
  padding-top: 1.2px;
}

.wishdrop-dis {
  display: flex;
  align-items: center;
  line-height: 6px;
}

.wishdrop-close-icon {
  position: absolute;
  top: 8px;
  right: 10px;
}

.wishdrop-price-child {
  display: flex;
  align-items: center;
  line-height: 4px;
}

.wishdrop-inr {
  font-size: 10px;
  line-height: 9px;
}

.wishdrop-brand {
  color: var(--dark);
  font-size: 12px;
  font-family: var(--font-family);
  margin-bottom: unset;
  font-weight: 500;
  line-height: 10px;
}

.wishdrop-color-parent {
  height: 100%;
  display: flex;
  align-items: end;
}

.wishdrop-brandname {
  color: #686767;
  font-weight: 300;
  font-family: Poppins;
  font-size: 11px;
  line-height: 14px;
  margin-bottom: 0 !important;
  text-overflow: ellipsis;
  height: 17px;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  max-width: 255px;
}

.simpadd {
  padding-top: 10px;
}

.wishdrop-details {
  display: flex;
  flex-direction: column;
  gap: 3.3px;
}

.wishdrop-color {
  display: flex;
  gap: 2px;
  margin-bottom: unset;
  align-items: center;
}

.wishdrop-variant-selected {
  font-size: 10px !important;
  font-weight: 400;
  margin-bottom: 0 !important;
  display: inline-block;
}

.wishdrop-colors {
  display: flex;
  gap: 2px;
  position: relative;
}

.wishlistDropDown .moreColors-wishlist {
  position: relative;
  left: 10px;
  padding-top: 2px;
}

.wishdrop-colors .plp-infoHolder-ConfigOptions-color {
  margin-left: -6px;
}

.wishdrop-more {
  color: var(--dark);
  font-size: 8px;
  font-weight: 500;
}

.strike-through-text {
  text-decoration: line-through;
}

.strike-through-text-currency::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 1px;
  right: 0;
  height: 1px;
  width: 100%;
  background-color: #787878;
  /* Adjust the color as needed */
}

.cartDropdown .upperWrapper {
  display: flex;
  gap: 10px;
}

.dropdownCartProd .imgWrapper-info {
  gap: 4.6px;
}

.dropdownCartProd h4 {
  color: var(--dark);
  font-size: 12px;
  font-family: var(--font-family);
  margin-bottom: unset;
  font-weight: 500;
}

.wishlistDropDown .dropdownCartProd .wishdrp-p {
  min-height: 34px;
}

.wishlistDropDown .plp-infoHolder-ConfigOptions-colorList {
  line-height: 15px;
}

.wishlistDropDown .plp-infoHolder-ConfigOptions-colorList-conf {
  line-height: 1rem;
}

.wish-drp {
  line-height: 0.8rem;
}

.dropdownCartProd p {
  min-height: 12px;
}

.cartdrop-productname {
  color: #686767;
  font-weight: 300;
  font-family: Poppins;
  font-size: 11px;
  line-height: 14px;
  margin-bottom: 0 !important;
  text-overflow: ellipsis;
  height: 17px;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  max-width: 273px;
}

.dropdownCartProd .fPrice {
  color: var(--dark);
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 600;
}

.dropdownCartProd svg {
  width: auto !important;
}

.dropdownWishlistProd {
  width: auto !important;
}

.dropdownCartProd .rPrice {
  color: #b6b6b6;
  font-size: 12px;
  font-family: var(--font-family);
  font-weight: 400;
  position: relative;
  text-decoration: line-through;
}

.dropdownCartProd .dis {
  color: #77353b;
  font-size: 12px;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 300;
}

.dropdownCartProd .imgWrapper {
  margin-top: 0%;
  background-color: var(--white);
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 6px;
}

.dropdownCartProd .imgWrapper img {
  margin-top: 0%;
  height: 70px !important;
  border-radius: 6px;
  width: 70px !important;
}

.dropdownWishlistProd .imgWrapper img {
  margin-top: 0%;
  height: 70px !important;
  border-radius: 6px;
  width: 70px !important;
}

.cart-name {
  position: relative;
  left: 2px;
}

.dropdownCartProd .configOptions {
  font-size: 9px;
  text-transform: capitalize;
  display: flex;
  font-weight: 400;
  font-family: var(--font-family);
  align-items: center;
}

.dropdownCartProd .configOptions .color {
  width: 8px;
  height: 8px;
  border-radius: 10px;
}

.cartDropdown .dropdownFooter span {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
}

.cartDropdown .dropdownFooter {
  text-align: center;
  margin-top: 20px;
  margin-left: 6px;
}

.cartDropdown .dropdownFooter > div {
  border-bottom: 1px solid #787878;
  display: flex;
  justify-content: space-between;
  padding-bottom: 6px;
  margin-bottom: 10px;
}

.cartDropdown .dropdownFooter button {
  width: 260px;
  height: 40px;
  border-radius: 6px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
  padding-top: 8px;
  padding-bottom: 8px;
}

.dropdownCartProd .bottomWrapper {
  text-align: end;
  text-transform: capitalize;
  font-size: 9px;
  font-weight: 300;
  font-family: var(--font-family);
}

.dropdownCartProd .bottomWrapper button {
  background-color: transparent;
  border: none;
  color: var(--dark);
}

.dropdownCartProd .bottomWrapper button:hover {
  text-decoration: underline;
}

.cartLoginPopup .modalClose {
  background-color: transparent;
  border: none;
}

.cartLoginPopup .modal-content {
  width: 100%;
  max-width: 760px;
  padding: 20px;
  font-family: var(--font-family);
}

.cartLoginPopup .modal-header {
  padding: 0;
  padding-bottom: 10px;
}

.cartLoginPopup .modal-title {
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
}

.cartLoginPopup .loginTile {
  border-radius: 6px;
  background: #f5f5f5;
  padding: 20px;
  width: 49%;
}

.cartLoginPopup .loginTile img {
  border-radius: 6px;
}

.loginTile button {
  color: black;
}

.cartLoginPopup .loginTile h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.cartLoginPopup .loginTile p {
  color: #787878;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  margin-bottom: 50px;
}

.cartLoginPopup .loginTile button {
  width: 100%;
  background: transparent;
  border-radius: 6px;
  border: 1px solid var(--dark);
  font-size: 14px;
  line-height: 11px;
  text-transform: uppercase;
  padding-top: 17px;
  padding-bottom: 17px;
}

.cartLoginPopupMobile h2 {
  font-size: 3vw !important;
}

.cartLoginPopupMobile .loginTile {
  display: flex;
  border-radius: 6px;
  background: #f5f5f5;
  padding: 20px;
  width: 49%;
  flex-flow: inherit;
}

.cartLoginPopupMobile .loginTile button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 12px;
}

.cartLoginPopupMobile .third-section {
  width: 100%;
}

.cartLoginPopupMobile .loginTile {
  display: flex !important;
  flex-flow: inherit !important;
  align-items: baseline !important;
}

@media screen and (max-width: 410px) {
  .cartLoginPopupMobile .loginTile button {
    position: absolute;
    font-size: 10px;
    bottom: 29px;
    max-width: 126px;
    margin-left: -5px;
  }
}

@media screen and (max-width: 370px) {
  .cartLoginPopupMobile .loginTile button {
    position: absolute;
    font-size: 9px;
    bottom: 29px;
    max-width: 120px;
  }

  .second-section {
    min-height: 148px;
  }
}

@media screen and (max-width: 350px) {
  .cartLoginPopupMobile {
    zoom: 50%;
  }

  .cartLoginPopupMobile .loginTile button {
    margin-left: 39px;
  }

  .cartLoginPopup .loginTile h2 {
    font-size: 15px !important;
  }
}

.plp-infoHolder-ConfigOptions-colorList {
  display: flex;
  grid-template-columns: repeat(auto-fill, 32px);
  column-gap: 5px;
  padding: 0;
}

header .plp-infoHolder-ConfigOptions-colorImage {
  border-radius: 10px;
  height: 10px;
  width: 10px;
}

.wish-pop {
  line-height: 0.5rem;
  margin-top: 5px;
}

.color-products-available {
  margin: 0;
  font-weight: 400;
}

.wsh-pr {
  padding-right: 2px;
}

.size-products-available {
  margin: 0;
  font-weight: 400;
}

.variant-selected {
  font-size: 10px !important;
  font-weight: 400;
}

.moreColors-wishlist {
  color: var(--dark);
  font-size: 9px;
  padding-top: 2px;
  font-weight: 300;
  font-family: var(--font-family);
}

.moreSizes-wishlist {
  font-family: var(--font-family);
  color: var(--dark);
  font-size: 8px;
  padding-top: 2px;
  font-weight: 500;
}

.b2BLoginRegister {
  font-family: var(--font-family);
  padding: 8px 20px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

@media (max-width: 991px) {
  .b2BLoginRegister {
    padding: 4px 10px;
    font-size: 10px;
    font-weight: 400;
  }
}
