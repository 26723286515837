.Toastify__toast {
    font-family: var(--font-family) !important;
    padding: unset !important;
    border-radius: 6px !important;
    min-height: 50px !important;
    font-size: 15px;
    line-height: 21px;
    font-weight: 500;
    background-color: var(--white) !important;
    color: #6f6f6f !important;
    margin-bottom: 1rem !important;
}

.Toastify__toast-container {
    padding: unset !important;
    width: 460px !important;
}

.Toastify__toast-body {
    margin: unset !important;
}

.Toastify__toast--success .Toastify__toast-body {
    border-left: 6px solid #71be4c;
}

.Toastify__toast-body::before {
    content: '';
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    height: 22px;
    width: 22px;
    position: absolute;
    left: 20px;
}

.Toastify__toast--success .Toastify__toast-body::before {
    background-image: url(../../../src/assets/icons/Toast/check-circle-fill.svg);
}

.Toastify__toast--error .Toastify__toast-body {
    border-left: 6px solid #cc0033;
}

.Toastify__toast--error .Toastify__toast-body::before {
    background-image: url(../../../src/assets/icons/Toast/x-circle-fill.svg);
}

.Toastify__toast-body {
    padding-left: 50px !important;
}

@media screen and (max-width: 980px) {
    .Toastify__toast-container {
      width: 64% !important;
      max-width: 460px;
      bottom: 1em !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
    }
}