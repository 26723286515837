.b2b {
  font-family: var(--font-family);
}

.b2bLandingHeading {
  text-align: center;
  font-size: 20px;
  font-weight: 300;
    letter-spacing: .12em;
    text-transform: uppercase;
  line-height: 26px;
}

.disable-scroll .lazy-load-container {
  position: sticky !important;
}

@media screen and (max-width: 992px) {
  .b2bLandingHeading {
    font-size: 22px;
    line-height: 30px;
  }
}

.imageSlider a > * {
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) -25%, rgba(0, 0, 0, 0.7) 45%);
  max-height: 600px;
}

.imageSlider .carousel-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: end;
}

.imageSlider .carousel-indicators {
  margin-bottom: 140px;
}

.imageSlider .carousel-indicators [data-bs-target] {
  width: 20px;
  height: 5px;
  border: initial;
  border-radius: 3px;
  opacity: 1;
}

.imageSlider .carousel-indicators .active {
  width: 40px;
  background-color: var(--wine);
  height: 5px;
}

@media screen and (max-width: 992px) {
  .imageSlider .loginCta {
    font-size: 12px;
    line-height: normal;
    padding: 8px 20px;
    margin-bottom: 20px;
  }

  .imageSlider .carousel-indicators {
    margin-bottom: 20px;
  }

  .imageSlider .carousel-item > * {
    aspect-ratio: 21/25;
    height: 100%;
  }
}

/* ICON SLIDER */

.iconSlider {
  display: flex;
  gap: 16px;
  margin-top: -110px;
  z-index: 1;
  position: relative;
}

.iconSlider > div {
  height: 204px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  text-align: center;
  background: var(--white);
  border: 1px solid #d9d9d9;
  padding: 20px;
  border-radius: 6px;
}

.iconSlider > div img {
  width: 60px;
  height: 60px;
}

.iconSlider > div h2 {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  height: 40px;
}

.iconSlider > div p {
  margin-bottom: unset;
  font-size: 14px;
  line-height: 18px;
}

@media screen and (max-width: 992px) {
  .iconSlider {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 0;
  }

  .iconSlider > div {
    height: 100%;
    padding: 10px;
  }

  .iconSlider > div img {
    width: 60px;
    height: 60px;
  }

  .iconSlider > div h2 {
    font-size: 14px;
    line-height: 18px;
  }

  .iconSlider > div p {
    font-size: 12px;
    line-height: 16px;
  }
}

/* STEP SLIDER */

.stepSliderWrapper,
.brandLogoWrapper {
  background-color: #f6f6f6;
  padding: 50px 0;
}

.stepSlider {
  margin-bottom: 0 !important;
}

.stepSlider .b2bLandingHeading {
  margin-bottom: 10px;
}

.stepSlider > p {
  line-height: 22px;
  text-align: center;
  margin-bottom: 50px;
}

.textCarousel .carousel-item > div > * {
  width: 39%;
}

.textCarousel h4 {
  font-size: 24px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  margin-bottom: 26px;
}

.textCarousel .innerText {
  height: 84px;
}

.textCarousel p {
  font-size: 18px;
  line-height: 28px;
  text-align: left;
  margin-bottom: unset;
}

.textCarousel img {
  aspect-ratio: 325/188;
  max-width: 100%;
}

.textCarousel .carousel-indicators {
  bottom: 66px;
  right: 188px;
}

.textCarousel .carousel-indicators [data-bs-target] {
  background-color: var(--grey-2);
  opacity: 1;
  border: initial;
  border-radius: 3px;
  height: 5px;
  width: 20px;
}

.textCarousel .carousel-indicators .active {
  background-color: var(--wine);
  width: 40px;
}

@media screen and (max-width: 768px) {
  .stepSliderWrapper {
    padding: 30px 0 50px 0;
  }

  .stepSlider {
    margin: 0 !important;
  }

  .stepSlider > p {
    margin-bottom: 20px;
  }

  .textCarousel .carousel-item > div > * {
    width: 100%;
  }

  .textCarousel h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 16px;
  }

  .textCarousel p {
    font-size: 12px;
    line-height: 18px;
  }

  .textCarousel .innerText {
    height: 36px;
  }

  .textCarousel .carousel-indicators {
    right: 0;
    bottom: -20px;
    margin-right: 0;
    margin-bottom: 0;
    justify-content: end;
  }
}

/* STATIC BANNER */

.staticBanner .b2bLandingHeading {
  margin-bottom: 10px;
}

.staticBannerItems {
  display: flex;
}

.staticBannerItems > div {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  text-align: left;
}

.staticBannerItems span {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d9d9d9;
  width: 40px;
  height: 40px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  font-weight: 600;
  line-height: 20px;
  margin-right: 20px;
}

.staticBannerItems h6 {
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.staticBannerItems p {
  line-height: 26px;
  margin-bottom: unset;
}

/* BRAND LOGO SLIDER  */

.brandLogo .b2bLandingHeading {
  margin-bottom: 30px;
}

.brandLogo > div > div {
  width: 33.33%;
}

.brandLogo > div > div > * {
  width: 100%;
}

@media screen and (max-width: 992px) {
  .brandLogoWrapper {
    padding: 20px 0;
  }
}

@media screen and (max-width: 768px) {
  .brandLogo > div > div {
    width: 100%;
  }
}

/* FAQ SLIDER */

.b2bFaqSlider .b2bLandingHeading {
  margin-bottom: 50px;
}

.FAQ_Question {
  color: #000000;
  font-weight: 600;
}

.b2bFaqSlider .accordion-item {
  border: 1px solid #d9d9d9 !important;
  border-top: 1px solid #d9d9d9 !important;
  border-radius: 6px;
  min-height: 90px;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.b2bFaqSlider .active-accordion-item {
  border: 1px solid var(--dark) !important;
}

.b2bFaqSlider .accordion-button {
  border-radius: 6px !important;
}

.b2bFaqSlider .accordion .accordion-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  text-transform: unset;
}

.b2bFaqSlider .accordion .accordion-button::after {
  background-color: var(--wine);
  background-image: url(../../Assets/Icons/minus.svg);
  background-size: 16px;
  background-position: center;
  aspect-ratio: 1/1;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.b2bFaqSlider .accordion .collapsed::after {
  background-image: url(../../Assets/Icons/plus.svg);
  background-color: #d1d1d1;
}

.b2bFaqSlider .accordion .accordion-button span {
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  color: #d8d8d8;
  margin-right: 20px;
}

.b2bFaqSlider .accordion .accordion-item:first-of-type .accordion-header {
  border-top: none !important;
}

.b2bFaqSlider .accordion-header {
  width: 100%;
}

.b2bFaqSlider .accordion {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.b2bFaqSlider .accordion .accordion-body {
  color: #787878;
  font-weight: 300;
}

@media screen and (max-width: 992px) {
  .b2bFaqSlider .accordion-item {
    padding: 0 20px;
  }

  .b2bFaqSlider .accordion .accordion-button span {
    font-size: 18px;
    line-height: 22px;
    margin-right: 10px;
  }

  .b2bFaqSlider .accordion .accordion-button {
    padding: 20px 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }

  .b2bFaqSlider .accordion .accordion-body {
    text-transform: unset;
    padding-bottom: 20px;
    font-size: 12px;
    line-height: 18px;
  }
}
