.signin-phone {
  font-weight: 500;
  margin-bottom: 16px;
  font-size: 14px;
  font-family: var(--font-family);
}

.phoneInput {
  display: flex;
  border: 1px solid #72777a;
  border-radius: 6px;
  font-size: 12px;
  padding-left: 10px;
  height: 40px;
  width: 98%;
}

.phoneInput:focus-within {
  border-color: var(--dark);
  color: var(--dark);
}

.loginInput input:focus {
  color: var(--dark);
}

.formInputs .loginInput input:focus-visible {
  border: none;
  outline: none;
}

.phoneInput input {
  border: none;
  margin-left: 10px;
  width: 98%;
  border-radius: 6px;
}

.phoneInput input:focus-visible {
  outline: none;
}

.phoneInput-CountryCode {
  display: flex;
  align-items: center;
  color: #72777a;
  margin: 10px 0;
  border-right: 1px solid #d9d9d9;
}

.phoneInput svg {
  margin: 0px 5px;
}

.validationError {
  color: #cc0033;
  font-size: 10px;
  margin-top: 4px;
}

.signin-tnc {
  font-size: 10px;
  color: var(--dark);
  margin-top: 17px;
  display: flex;
  gap: 10px;
  font-family: var(--font-family);
}

.signin-tnc > input {
  appearance: none;
  border: 1px solid var(--dark);
  width: 16px;
  height: 16px;
  cursor: pointer;
  border-radius: 3px;
  position: relative;
}

.signin-tnc > input:checked {
  background-color: var(--wine);
  border: 1px solid var(--wine);
}

.signin-tnc > input:before {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
}

.signin-tnc > input:checked:before {
  background: url('/src/assets/icons/Check.svg') no-repeat center center;
  background-size: contain;
  width: 8px;
  height: 8px;
}

.signin-tnc a {
  font-weight: 600;
  text-decoration: underline;
  color: var(--dark);
}

.signin-btn {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.signin-continue {
  border-radius: 6px;
  text-align: center;
  padding: 10px;
  width: 260px;
  font-size: 12px;
  font-family: var(--font-family);
  font-weight: 400;
}
