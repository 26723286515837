.multilevelSideNav {
    width: 100% !important;
    position: absolute;
}

ul.multisidenavmenu {
    width: 100%;
    padding: 20px 20px 0 20px;
    display: block;
    position: relative;
    margin-bottom: 0;
}

.multisidenavmenu a {
    color: #000;
    text-decoration: none;
}

.multisidenav ul li {
    list-style-type: none;
    width: 100%;
    background-color: #fff;
    margin-bottom: 20px;
    font-size: 14px;
    padding: 20px;
    border-radius: 5px;
    color: #000;
    text-transform: uppercase;
    font-family: 'poppins';
    position: relative;
}

.nav-arr {
    position: absolute;
    right: 10px;
    top: 15px;
}

.accordion-panel-ul {
    padding-left: 1rem!important ;
}

.rotate-negative-90 {

    transform: rotate(-90deg);

}

.rotate-90 {
    transform: rotate(90deg);
}


.sidebar::-webkit-scrollbar {
    display: none;
  } 

.rightArr{
    height: 20px;
    margin: 5px 6px 0 0;
}

.m-logo-img {
    width: 220px;
    height: 35px;
    margin: 10px 20px;
}

.m-header .sideback {
    display: flex;
    color: #000;
    width: fit-content;
    margin: 10px 15px;
}

.m-header .side-back {
    margin-left: 5px;
    margin-top: 5px;
    font-size: 14px;
}

li.cat_active {
    background: #000 !important;
    color: #fff !important;
}

.mside-btn {
    padding: 20px;
    margin: 0 20px 20px 20px;
    text-align: center;
    width: 100%;
    max-width: 290px;
    border-radius: 5px;
    text-transform: uppercase;
}

@media screen and (min-width: 601px) and (max-width: 990px) {
    .mside-btn {
        max-width: 96%;
    }

    .m-header .sideback {
       align-items: center;
    }

    .m-header .sideback span{
        margin-top: 1px;
     }
}

@media screen and (min-width: 320px) and (max-width: 600px) {
    .mside-btn {
        color: #fff;
        background-color: #000;
        max-width: 89%;
        padding: 10px;
    }
}