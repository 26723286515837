*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  color: var(--dark);
  font-family: var(--font-family);
  font-size: var(--font-size);
  transition: all var(--transition-timing) var(--transition-timing-function);
  overflow-x: hidden;
}

*::selection {
  background: var(--dark);
  color: var(--white);
}

body {
  padding-right: 0 !important;
}

body.modal-open {
  overflow: visible !important;
  width: 100%;
  right: 0;
  left: 0;
}

a {
  text-decoration: none;
}

/* UTILITY CSS */
.mr-14 {
  margin: 0 15px 0 -4px;
  width: 38px;
  height: 36px;
}

.tab-view-mr {
  margin-bottom: 12px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}

.errorShake {
  animation: var(--shake-animation);
}

.user-select-none {
  user-select: none;
}

.p-20 {
  padding: 20px !important;
}

.SliderWrapper {
  display: flex;
  flex-direction: column;
  gap: 59px;
}

.carousel-indicators {
  margin-bottom: 0;
}

/* skeleton */
.skeleton {
  border-radius: 5px;
  background-size: 200% 100%;
  -webkit-animation: 1.5s shine linear infinite;
  animation: 1.5s shine linear infinite;
}

/* BUTTON COMPONENT */
.page-cta {
  padding: 24px 18px;
  background-color: var(--white);
  border: 1px solid var(--dark);
  border-radius: 8px;
  width: 450px;
  font-size: 20px;
}

@media (max-width: 667px) {
  .page-cta {
    width: 280px !important;
  }

  .telephone {
    display: block;
  }
}

@media screen and (max-width: 576px) {
  .page-cta {
    width: 248px;
  }
}

@media (max-width: 400px) {
  .page-cta {
    width: 310px !important;
  }
}

.offerRadio {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  top: -1px;
  left: 10px !important;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  outline: none;
  vertical-align: middle;
  position: relative;
}

.offerRadio:checked {
  background-color: #d9d9d9;
}

@media screen and (max-width: 376px) {
  .offerRadio {
    width: 17px;
    height: 17px;
  }
}

@media screen and (max-width: 400px) {
  .offerRadio {
    left: -5px !important;
  }
}

.gbGrid {
  width: 100%;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
  font-family: var(--font-family);
}

@media screen and (max-width: 1340px) {
  .gbGrid {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.blackCta {
  background-color: var(--wine);
  color: var(--white);
  border: 1px solid var(--wine);
}

.blackCta:hover {
  cursor: pointer;
}

@media screen and (min-width: 992px) {
  .whiteCta:hover {
    background-color: var(--wine);
    color: #ffffff;
    cursor: pointer;
    border: 1px solid var(--wine);
  }
}

@media screen and (max-width: 991px) {
  .whiteCta:active {
    background-color: var(--wine);
    color: var(--white);
    cursor: pointer;
    border: 1px solid var(--wine);
  }
}

.blackCta:disabled,
.whiteCta:disabled {
  background-color: #d9d9d9;
  color: #787878;
  border: 1px solid #d9d9d9;
  cursor: default;
}

.whiteCta.selected {
  background-color: var(--wine);
  color: var(--white);
  border: 1px solid var(--wine);
}

.whiteCta {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #4d4d4d;
}

@media screen and (max-width: 991px) {
  .main-content {
    min-height: 71.5vh;
  }
  
  .SliderWrapper {
    gap: 30px
  }
}

@media screen and (max-width: 768px) {
  .main-content {
    min-height: 67.5vh;
  }
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.textEllipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
}
