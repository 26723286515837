.gst {
  font-family: var(--font-family);
}

.gst > Form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.gst-inputText {
  font-size: 10px;
  position: relative;
  font-weight: 500;
  top: 8px;
  left: 10px;
  padding: 0px 5px;
  z-index: 10;
  background-color: white;
  width: fit-content;
  font-family: var(--font-family);
}

.gst-businessHeading {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.gst-selector {
  display: flex;
  align-items: center;
  gap: 8px;
}

.gst-option {
  border: 1px solid var(--wine);
  height: 16px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: var(--white);
  cursor: pointer;
}

.gst-option.selected {
  border: 1px solid var(--wine);
  background: radial-gradient(circle at 50% 50%, var(--wine) 50%, transparent 50%);
}

.gst-Info {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  font-weight: 500;
}

.gst-Info > div {
  font-size: 11px;
  color: #787878;
  font-weight: 400;
}

.gst-inputHeader {
  position: relative;
}

.gst-inputHeader > input,
.gst-entityType {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #72777a;
  border-radius: 6px;
  font-size: 12px;
  padding: 10px;
  font-family: var(--font-family);
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.gst-inputHeader > input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-text-fill-color: #000 !important; /* Keep the text color consistent */
}

.gst-inputHeader > input:focus-visible,
.gst-entityType.filled {
  outline: none;
  border: 1px solid var(--dark);
}

.gst-inputHeader > input:not(:placeholder-shown) {
  color: var(--dark);
  border: 1px solid var(--dark);
}

.gst-entityTypeValue.valid {
  color: var(--dark);
}

.gst-entityType {
  cursor: pointer;
  color: #777777;
}

.gst-entityType.open {
  border-radius: 6px 6px 0 0;
}

.gst-entityType-dD {
  position: absolute;
  left: 0;
  right: 0;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: var(--white);
  z-index: 11;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  color: var(--dark);
  max-height: 170px;
  overflow: auto;
}

.gst-entityType-dD > div {
  line-height: 22px;
  font-size: 12px;
}

.gst-downArrow {
  position: relative;
  display: inline;
}

.gstin-btn {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.gstin-submit {
  border-radius: 6px;
  text-align: center;
  padding: 10px;
  width: 260px;
  font-size: 12px;
  font-family: var(--font-family);
  text-transform: uppercase;
  font-weight: 400;
}
