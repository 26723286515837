footer {
  background-color: #f5f5f5;
  padding-bottom: 51px;
  margin-top: 50px;
  font-family: var(--font-family);
  z-index: 1;
}

footer .accordionExpanded {
  display: flex;
  justify-content: end;
  padding: 8px 11px 6px 0px !important;
}

footer .footer {
  width: 100%;
  padding: 0 16px;
}

footer .popularSearch {
  font-size: 14px;
  text-transform: uppercase;
  line-height: 24px;
}

footer .popularSearch button {
  border-right: 1px solid var(--dark);
  padding-right: 8px;
  line-height: initial;
  margin: 4px 0;
  margin-right: 8px;
}

footer .popularSearch a:first-child {
  padding-left: 0;
}

footer .popularSearch a:last-child {
  border-right: none;
}

footer .popularSearch span a {
  font-weight: 700;
}

footer .readMore {
  color: var(--wine);
  border: none;
  background-color: transparent;
  font-weight: 500;
  font-size: 14px;
  font-weight: 500;
  margin-left: 7px;
}

footer .secondrow ul {
  list-style: none;
  padding-left: 0;
  font-family: var(--font-family);
}

footer h2 {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 16px;
  margin: 0;
}

footer .secondrow ul li {
  color: var(--dark);
  font-family: var(--font-family);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px;
  text-transform: uppercase;
}

footer .secondrow a {
  color: var(--dark);
  white-space: nowrap;
  font-weight: 300;
}

footer .accordion-item {
  background-color: initial;
}

@media (min--moz-device-pixel-ratio: 1.49) and (max--moz-device-pixel-ratio: 1.51),
  (-o-min-device-pixel-ratio: 149/100) and (-o-max-device-pixel-ratio: 151/100),
  (-webkit-min-device-pixel-ratio: 1.49) and (-webkit-max-device-pixel-ratio: 1.51),
  (max-width: 1400px),
  (min-device-pixel-ratio: 1.49) and (max-device-pixel-ratio: 1.51) {
  footer .footer {
    zoom: 80%;
    width: 100%;
    padding-right: 10%;
    padding-left: 10%;
  }
}

@media screen and (max-width: 768px) {
  .footer .accordionExpanded {
    height: 42px !important;
  }

  footer {
    padding-top: 55px;
  }

  footer .secondrow ul li {
    font-size: 12px;
    line-height: 30px !important;
  }

  footer {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .footer-wrapper {
    padding-top: 10px !important;
  }

  .button-reset {
    font-size: 12px;
  }

  .button-reset::after {
    top: 39% !important;
    height: 28% !important;
  }

  .firstrow {
    padding-top: 3rem;
  }

  .social_links {
    gap: 20px;
    justify-content: center;
  }
}

@media screen and (min-width: 1440px) {
  footer .footer {
    width: 1251px;
  }
}

@media screen and (min-width: 440px) {
  footer .secondrow ul {
    line-height: 36px;
  }
}

@media screen and (max-width: 767px) {
  footer {
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 1024px) {
  footer h2 {
    font-size: 12px;
  }
}

@media screen and (max-width: 426px) {
  .footer .img-fluid {
    max-width: 90%;
  }

  .footer .popularSearch {
    font-size: 14px;
  }
}

@media screen and (max-width: 420px) {
  .popularSearch .button-reset::after {
    top: 20% !important;
    height: 60% !important;
  }
}

@media screen and (max-width: 649px) {
  footer h2 {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 649px) and (max-width: 1024px) {
  .firstrow {
    padding-top: 0 !important;
  }

  .firstrow-wrp {
    margin-top: 1.5rem !important;
  }
}

@media screen and (min-width: 649px) and (max-width: 990px) {
  footer h2 {
    font-size: 14px !important;
  }
}

footer .accordion-item .accordion-button {
  background-color: transparent;
  padding: 20px;
  color: var(--dark) !important;
}

footer .accordion-item .accordion-button:focus {
  border: none;
  box-shadow: none;
}

footer .accordion-item .accordion-button::after {
  align-self: baseline;
  background-image: url('assets/icons/caret-down-light.svg');
  background-size: 32px;
  width: 32px;
  height: 32px;
}

@media screen and (max-width: 820px) {
  footer .accordion-button:not(.collapsed)::after {
    height: 32px;
    width: 32px;
    border-radius: 10px;
    background-color: #d9d9d9;
  }
}

@media screen and (max-width: 991px) {
  #my-footer .accordion-button {
    font-size: 15px !important;
    line-height: 22.5px;
    font-weight: 400;
    text-transform: capitalize;
  }
}

footer .accordionExpanded .accordion-button {
  padding: 0;
  box-shadow: none;
  width: fit-content;
}

@media screen and (min-width: 820px) and (max-width: 860px) {
  footer .accordionExpanded {
    height: 40px !important;
    position: relative;
    top: -25px;
    right: 1px;
  }
}

/* my-footer */
#my-footer {
  padding-left: 70px;
  padding-right: 70px;
}

#my-footer .accordion {
  background-color: #f5f5f5;
}

.footer-wrapper {
  margin: 0 auto;
  padding-top: 1rem;
  border-bottom: 1px solid #d9d9d9;
}

@media screen and (min-width: 427) and (max-width: 767px) {
  #my-footer {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

@media screen and (max-width: 990px) {
  #my-footer {
    padding-left: 0.9rem;
    padding-right: 0.9rem;
  }

  .firstrow {
    padding-top: 2rem;
  }

  .first-row-card-details {
    margin: 10px !important;
  }

  .first-row-card-icons {
    height: 53px;
    width: 90px;
  }

  .first-row-card-details-title {
    font-size: 12px !important;
  }

  .first-row-card-details-subtitle {
    font-size: 10px !important;
  }

  .first-row-card-wrapper {
    grid-row-gap: 17px !important;
  }
}

@media screen and (max-width: 991px) {
  #my-footer {
    padding: 0px !important;
  }

  #my-footer .footer-wrapper {
    padding: 0px !important;
    margin-top: 1.5em;
  }
}

@media screen and (max-width: 371px) {
  .footer-wrapper .footer-close-btn button {
    padding: 2px !important;
    top: -27px !important;
    left: 8px;
  }

  .firstrow {
    padding-top: 0px !important;
  }
}

@media screen and (min-width: 371px) and (max-width: 768px) {
  .footer-wrapper .footer-close-btn button {
    padding: 5px !important;
    top: -29px !important;
    border-radius: 10px !important;
    left: 4px !important;
  }
}

.first-row-card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-bottom: 20px;
}

.vertical-line {
  width: 1px;
  height: 35px;
  background-color: #3e3e3e;
  margin: 8px 16px 0px 0px;
}

.first-row-card:last-child .vertical-line {
  display: none;
}

@media (max-width: 1023px) {
  .first-row-card-wrapper {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }

  .first-row-card {
    align-items: center;
    width: 100%;
  }

  .first-row-card-icons {
    margin-right: 10px;
  }

  .first-row-card-details {
    flex-grow: 1;
  }
}

@media (max-width: 767px) {
  .first-row-card-details-title {
    font-size: 14px;
  }

  .first-row-card-details-subtitle {
    font-size: 12px;
  }
}

.first-row-card {
  display: flex;
}

.first-row-card-details {
  margin: 0px 10px;
}

@media screen and (max-width: 420px) {
  .first-row-card {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0rem !important;
  }

  .first-row-card p {
    font-size: 10px !important;
    line-height: 16px !important;
    font-weight: 300;
  }

  .first-row-card h2 {
    line-height: 16px !important;
    font-size: 12px !important;
    font-weight: 500;
  }
}

@media screen and (max-width: 376px) {
  .first-row-card h2 {
    font-size: 10px !important;
  }

  .first-row-card p {
    font-size: 8px !important;
  }
}

@media screen and (min-width: 420px) and (max-width: 605px) {
  .first-row-card p {
    font-size: 10px !important;
    line-height: 16px !important;
    font-weight: 300;
  }

  .first-row-card h2 {
    line-height: 16px !important;
    font-size: 12px !important;
    font-weight: 500;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1065px) {
  .first-row-card h2 {
    font-size: 14px !important;
  }

  .first-row-card p {
    font-size: 12px !important;
  }
}

.first-row-card h2 {
  color: var(--dark);
  font-family: var(--font-family);
  font-size: 0.9em;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 24px;
  text-transform: uppercase;
}

.first-row-card p {
  color: var(--dark);
  font-family: var(--font-family);
  font-size: 0.8em;
  font-style: normal;
  font-weight: 300;
}

.first-row-card-details-title {
  font-weight: 700;
  color: #242424;
  font-size: 14px;
}

.first-row-card-details-subtitle {
  font-weight: 300;
  font-size: 12px;
  line-height: 19px;
}

.first-row-card-icons {
  width: 50px;
  height: 50px;
}

.button-reset {
  border: none;
  color: var(--dark);
  background-color: transparent;
  font-size: 12px;
  padding: 0px 8px;
  /* margin: 5px 0; */
  font-weight: 300;
  cursor: pointer;
  position: relative;
  padding-left: 0;
  margin-right: 8px;
  line-height: 36px;
  text-align: justify;
}

.button-reset::after {
  content: '';
  display: block;
  position: absolute;
  border-right: solid 0.8px #111;
  top: 32%;
  right: 0%;
  height: 35%;
}

.button-reset:last-child::after {
  display: none;
}

@media screen and (max-width: 325px) {
  .button-reset {
    font-size: 8px !important;
  }
}

@media screen and (max-width: 420px) {
  .button-reset {
    font-size: 12px !important;
    line-height: 14px !important;
    text-align: justify;
  }

  .commercial-links img {
    width: 140px !important;
  }

  .social-image-wrapper {
    justify-content: center !important;
    gap: 20px !important;
  }

  .firstrow {
    padding-top: 0px !important;
  }

  .secondrow .categorylist-links ul li a {
    font-size: 12px !important;
  }

  .secondrow .categorylist-links h2 {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 420px) and (max-width: 605px) {
  .commercial-links img {
    width: 140px !important;
  }

  .social-image-wrapper {
    justify-content: center !important;
    gap: 20px !important;
  }

  .firstrow {
    padding-top: 0px !important;
  }
}

.firstrow,
.secondrow,
.thirdrow {
  border-bottom: 1px solid #d9d9d9;
}

.secondrow,
.thirdrow,
.fourthrow {
  padding: 30px 0;
}

.secondrowresponsive {
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 767px) and (max-width: 990px) {
  .secondrow,
  .thirdrow,
  .fourthrow {
    margin-top: 10px;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 991px) {
  .secondrowresponsive {
    gap: 0px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1235px) {
  .secondrowresponsive {
    justify-content: space-between;
    gap: 55px;
  }
}

@media screen and (max-width: 955px) {
  .secondrow,
  .thirdrow,
  .fourthrow {
    padding: 30px 0px 30px 0px;
  }
}

.social_links {
  align-items: center;
}

.social_links a {
  margin-top: 20px;
}

.icon-social {
  height: 45px;
  width: 45px;
  background: black;
  display: flex;
  padding: 5px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.icon-social img {
  width: 25px;
  height: 25px;
}

.store-wrapper {
  max-width: 300px;
}

.ft-margin:last-child {
    padding-top: 30px !important;
  }

@media screen and (min-width: 769px) and (max-width: 991px) {
    .thirdrowresponsive {
        justify-content: center;
    }
}

.bottomFooter h2 {
  all: unset;
}
