.loyaltyTnc {
  font-family: var(--font-family);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.loyaltyTnc h4 {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 12px;
}

.loyaltyTnc ul,
.loyaltyTnc ol,
.loyaltyTnc p {
  font-size: 12px;
  font-weight: 300;
  line-height: 22px;
  text-align: justify;
  margin-bottom: 0;
}

.channels-ul {
  padding-left: 25px !important;
}

.loyaltyTnc ul {
  padding-left: 24px;
}

.loyaltyTnc ol {
  list-style-type: none;
  counter-reset: list-counter;
  padding-left: 0;
}

.loyaltyTnc ol li {
  counter-increment: list-counter;
}


.loyaltyTnc .scrollWrapper {
  width: 100%;
}

.loyaltyTnc table {
  width: 100%;
  border: 1px solid var(--dark);
  table-layout: fixed;
  margin-top: 20px;
  margin-bottom: 8px;
}

@media screen and (max-width: 1200px) {
  .loyaltyTnc .scrollWrapper {
    overflow-x: scroll;
  }

  .loyaltyTnc table {
    width: 935px;
    overflow-x: scroll;
  }
}

.loyaltyTnc table tr,
.loyaltyTnc table th,
.loyaltyTnc table td {
  border: 1px solid var(--dark);
}

.loyaltyTnc table th,
.loyaltyTnc table td {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  text-align: center;
  height: 50px;
  padding: 0 20px;
}

.loyaltyTnc table th {
  font-weight: 500;
}

.loyaltyTnc .channels-ul li:before {
  content: counter(list-counter, lower-alpha) '. ' !important;
}

.custom-number-ol {
  counter-reset: item !important
}

.ol-child-li {
  display: flex !important;
  gap: 10px;
}

.ol-child-li:before {
  content: counters(item, ".") " " !important;
  counter-increment: item
}

.parent-list:before {
  content: counters(item, ".") "." !important;
  counter-increment: item
}

.prevnt-flex {
  display: block !important;
}

.parent-list>div {
  max-width: 100%;
}

@media screen and (max-width:1200px) {
  .custom-padding-right {
    padding-right: 16px;
  }
}

.parent-list {
  display: flex !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 12px;
}


/* Reponsive */

@media only screen and (max-width: 768px) {
  .loyaltyTnc h4 {
    font-size: 12px;
    line-height: 16px;
  }

  .loyaltyTnc ul,
  .loyaltyTnc ol,
  .loyaltyTnc p {
    font-size: 10px;
    line-height: 16px;
  }

  .loyaltyTnc table th,
  .loyaltyTnc table td {
    font-size: 10px;
    line-height: 14px;
    padding: 0 10px;
  }
}
