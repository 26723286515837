.bouncing-loader {
    display: flex;
    justify-content: center;
    margin: 40px auto;
}

.bouncing-loader>div {
    width: 16px;
    height: 16px;
    margin: 3px 2px;
    border-radius: 50%;
    background-color: black;
    opacity: 1;
    animation: bouncing-loader 0.7s infinite alternate;
}

@keyframes bouncing-loader {
    to {
        transform: translateY(-10px);
    }
}

.bouncing-loader>div:nth-child(2) {
    animation-delay: 0.15s;
}

.bouncing-loader>div:nth-child(3) {
    animation-delay: 0.25s;
}