.popup {
  font-family: var(--font-family);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.popup-outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

.popup-inner {
  position: relative;
  width: 100%;
  max-width: 460px;
  background-color: #fff;
  z-index: 999999;
  border-radius: 6px;
  padding-left: 40px;
  padding-right: 40px;
  height: 100%;
  max-height: 580px;
  overflow-y: scroll;
}

.popup-inner::-webkit-scrollbar {
  width: 0;
  display: none;
}

.popup-close {
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 18px;
}

.text-validation {
  color: #c03;
  margin-bottom: 0;
  font-size: 9px;
  margin-top: 5px;
  white-space: nowrap;
}

.verifyCodeInvalid {
  padding-left: 10px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.disable-scroll {
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  .popup {
    align-items: flex-end;
  }

  .popup-inner {
    max-height: 572px;
    max-width: unset;
    padding: 38px 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .text-validation {
    white-space: normal;
  }
}