.errorBoundry {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--font-family);
}

.invalidUrl {
  flex-direction: row;
  justify-content: center;
  gap: 40px;
  margin-top: 135px;
}

.errorImg {
  margin-top: 2.265rem;
  width: 18vw;
  object-fit: contain;
}

.errorBoundry h2 {
  font-size: 3vw;
  font-weight: normal;
}

.invalidUrl h3 {
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 12%;
  margin-bottom: 10px;
}

.invalidUrl p {
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 12%;
  color: var(--wine);
  margin-bottom: 50px;
  text-align: center;
}

.invalidUrl button {
  width: 100%;
  max-width: 364px;
  height: 40px;
  border-radius: 3px;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0%;
}

.errorButton {
  gap: 20px;
}

.tryButton {
  line-height: 3;
  width: 17em;
  border-radius: 6px;
  margin-top: 20px;
  font-family: var(--font-family);
  margin-bottom: 50px;
}

@media screen and (max-width: 786px) {
  .errorBoundry {
    padding: 0 20px;
  }

  .invalidUrl {
    flex-direction: column;
  }

  .errorButton {
    flex-direction: column;
    gap: 0px !important;
  }

  .errorImg {
    width: 40vw;
  }

  .tryButton {
    font-size: 12px;
  }
}
