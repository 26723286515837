/*Slide Menu CSS  */

.menu-sec {
    background-color: #fff;
    margin: 15px 10px;
    padding: 10px 5px;
    border-radius: 5px;
}

.accordian-menu-sec {
    background-color: #fff;
    margin: 15px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
}

.menu-logo-sec {
    display: flex;
    padding: 14px 22px;
    margin-bottom: 10px;
    position: absolute;
    right: 0;
    cursor: pointer;
    z-index: 99;
}

.menu-img {
    max-width: 35px;
    width: 100%;
    border-radius: 50%;

}

.menu-logo-sec .m-logo img {
    width: 80%;
    margin-left: 15%;
}

.accordian-menu-sec .accordion__heading .accordion__button {
    padding: 0 !important;
}

.mob-menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    padding: 15px 10px;
    border-bottom: 1px solid #dfdfdf;
}

.panel-group {
    margin: 0;
}

.multilevelSideNav .accordion-item {
    margin: 10px 10px;
}

.panel-heading {
    padding: 0;
}

.hr-sec {
    margin: 35px 10px;
}

.my-account {
    margin: 20px 10px;
}

.my-account h4 {
    font-size: 24px;
}

.my-account h6 {
    font-size: 17px;
}

#rfs-btn {
    font-size: 27px !important;
    border: none;
    padding-left: 0;
}

.ReactFlagsSelect-module_selectFlag__2q5gC svg {
    border-radius: 50%;
    width: 40px;
    height: 30px;
}


.mobile-menu-search {
    width: 100%;
    padding: 10px 15px;
    border: none;
    background: #F2F2F2 !important;
    font-size: 16px;
}

.mobile-menu-search:focus {
    box-shadow: none;
    border: none;
    background: #F2F2F2 !important;
}

.mobile-menu-search::placeholder {
    color: rgba(0, 0, 0, .6);

}

.ReactFlagsSelect-module_selectBtn__19wW7:after {
    border-top: 0px solid transparent !important;
    border-left: 0px solid #4d4d4d !important;
    display: inline-block;
    content: '';
    height: 13px !important;
    width: 13px !important;
    margin-right: 12px;
    border-bottom: 2px solid currentColor !important;
    border-right: 2px solid currentColor !important;
    transform: rotate(-45deg) !important;
}

.ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded="true"]:after {
    border-top: 0px solid transparent !important;
    border-left: 0px solid #4d4d4d !important;
    display: inline-block;
    content: '';
    height: 13px !important;
    width: 13px !important;
    margin-right: 12px;
    border-bottom: 2px solid currentColor !important;
    border-right: 2px solid currentColor !important;
    transform: rotate(-135deg) !important;
}

.loyaltypoint .accordion__button::before {
    display: none !important;

}

.loyaltypoint .accordion__button[aria-expanded='true']::before,
.loyaltypoint .accordion__button[aria-selected='true']::before {
    display: none !important;
}

.loyaltypoint .accordion__button {
    position: relative;
    background-color: transparent !important;
    color: #111;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    padding: 5px !important;
}

.loyaltypoint .accordion__button:after {
    display: inline-block;
    content: url('assets/icons/caret-down-light.svg');
    margin-right: 12px;
    position: absolute;
    top: 24px;
    right: 2px;
}

.loyaltypoint .accordion__button:hover {
    background-color: #f8f8f8 !important;
}

.loyaltypoint .accordion__button[aria-expanded='true']::after,
.loyaltypoint .accordion__button[aria-selected='true']::after {
    transform: rotate(180deg);
    float: right;
}