

.gstCertificate-inputHeader {
    font-size: 14px;
    font-weight: 500;
    margin-top: 20px;
    font-family: var(--font-family);
}

.gstCertifcate-uploaded {
    display: flex;
    align-items: start;
    gap: 10px;
}

.gstCertificate-fileInfo {
    width: 100%;
}

.gstCertificate-fileDetails {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.gstCertificate-fileOptions {
    display: flex;
    gap: 10px;
}

.gstCertificate-fileView {
    font-size: 13px;
    font-weight: 500;
}

.gstCertificate-fileName {
    font-size: 13px;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.gstCertificate-fileLoading {
    height: 3px;
    width: 100%;
    border-radius: 2px;
    font-family: var(--font-family);
}

.gstCertificate-fileLoading.green {
    background: #23B93C;
}

.gstCertificate-fileLoading.red {
    background: #CC0033;
}

.gstCertificate-fileError {
    font-size: 14px;
    font-weight: 500;
    color: #CC0033;
}

.gstCertificate-input {
    margin-top: 16px;
}

.gstCertificate-input > img {
    cursor: pointer;
    width: 100%;
}

.gstCertificate-input > input {
    display: none;
}

.gstCertificate-btn {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.gstCertificate-submit {
    border-radius: 6px;
    text-align: center;
    padding: 10px;
    width: 260px;
    font-size: 12px;
    font-family: var(--font-family);
}