.videosContainer {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-color: var(--dark);
    border-radius: 6px;
}

.videosContainer::after {
    position: absolute;
    width: 100%;
    height: 100%;
    border: none;
    background-repeat: round;
    background-color: rgba(26, 25, 25, 0.2);
}

.video_banner .slick-track {
  display: flex;
  justify-content: center;
}

.video_banner .slick-track .slick-slide {
  width: 413px !important;
  height: 262px;
}

#playBtn {
    position: absolute;
    inset: 0;
    object-fit: cover;
    background: rgba(5, 5, 5, 0.7);
    border: 10px;
    z-index: 3;
}

.videosContainer video {
    object-fit: contain;
    width: 100%;
}
  
.videosContainer iframe {
    width: 100%;
    height: 100%;
}

.ytPlayBtn {
    position: absolute;
    width: 100%;
    height: 100%;
    border: none;
    background-repeat: round;
    background-color: rgba(0, 0, 0, 0.2);
}

.mediaPlayer video {
    width: 100%;
    min-height: 70vh;
    max-height: 80vh;
    background-color: var(--dark);
    margin-bottom: -5px;
    border-radius: 8px;
}
  
.mediaPlayer .modal-content {
    border-radius: 8px;
}
  
.mediaPlayer iframe {
    width: 100%;
    min-height: 70vh;
    max-height: 80vh;
    background-color: var(--dark);
    margin-bottom: -5px;
    border-radius: 8px;
}

@media screen and (max-width: 820px) {
    .mob-small-exclu {
      height: 330px;
    }
  
    .videosContainer-mobile-homeOffers {
      height: 300px !important;
    }
}

@media screen and (max-width: 768px) {
    .mob-small-exclu {
      height: 335px;
    }
  
    .videosContainer-mobile-homeOffers {
      height: 300px !important;
    }
}

@media screen and (max-width: 709px) {
    .mob-small-exclu {
      height: 330px;
    }
  
    .videosContainer-mobile-homeOffers {
      height: 300px !important;
    }
}

@media screen and (max-width: 480px) {
    .mob-small-exclu {
      height: 220px;
    }
  
    .videosContainer-mobile-homeOffers {
      height: 300px !important;
    }
}

@media screen and (max-width: 426px) {
    .videosContainer .playbtn {
      width: 10%;
      height: 30%;
    }
  
    .mob-small-exclu {
      height: 194px;
    }
  
    .videosContainer-mobile-homeOffers {
      height: 280px !important;
    }
}

@media screen and (max-width: 376px) {
    .mob-small-exclu {
      height: 170px;
    }
  
    .videosContainer-mobile-homeOffers {
      height: 250px !important;
    }
}

@media screen and (max-width: 320px) {
    .videosContainer-mobile-homeOffers {
      height: 220px !important;
    }
  
    .videosContainer-mobile-exclusives {
      height: 220px !important;
    }
  
    .mob-small-exclu {
      height: 140px !important;
    }
}