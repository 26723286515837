@font-face {
  font-family: 'Poppins';
  font-weight: 100;
  font-style: normal;
  src: url('../Poppins/Poppins-Thin.ttf')
    format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 200;
  font-style: normal;
  src: url('../Poppins/Poppins-ExtraLight.ttf')
    format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  font-style: normal;
  src: url('../Poppins/Poppins-Light.ttf')
    format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  font-style: normal;
  src: url('../Poppins/Poppins-Regular.ttf')
    format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  font-style: normal;
  src: url('../Poppins/Poppins-Medium.ttf')
    format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  font-style: normal;
  src: url('../Poppins/Poppins-SemiBold.ttf')
    format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  font-style: normal;
  src: url('../Poppins/Poppins-Bold.ttf')
    format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 800;
  font-style: normal;
  src: url('../Poppins/Poppins-Bold.ttf')
    format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 900;
  font-style: normal;
  src: url('../Poppins/Poppins-Black.ttf')
    format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 100;
  font-style: italic;
  src: url('../Poppins/Poppins-ThinItalic.ttf')
    format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 200;
  font-style: italic;
  src: url('../Poppins/Poppins-ExtraLightItalic.ttf')
    format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  font-style: italic;
  src: url('../Poppins/Poppins-LightItalic.ttf')
    format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  font-style: italic;
  src: url('../Poppins/Poppins-Italic.ttf')
    format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  font-style: italic;
  src: url('../Poppins/Poppins-MediumItalic.ttf')
    format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  font-style: italic;
  src: url('../Poppins/Poppins-SemiBoldItalic.ttf')
    format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  font-style: italic;
  src: url('../Poppins/Poppins-BoldItalic.ttf')
    format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 800;
  font-style: italic;
  src: url('../Poppins/Poppins-BoldItalic.ttf')
    format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 900;
  font-style: italic;
  src: url('../Poppins/Poppins-BlackItalic.ttf')
    format('woff2');
}