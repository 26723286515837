.login {
  width: 100%;
  height: auto;
  font-family: var(--font-family);
  padding-top: 100px;
}

.login__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.login__form img {
  width: 100%;
  max-width: 273.996px;
  height: auto;
}

.login__form .subText {
  color: #000000;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  margin-top: 30px;
  font-family: Poppins;
}

.login__form .register_amt {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 32px;
  text-align: center;
  white-space: nowrap;
}

.login__form .formInputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formInputs label {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 20px;
}

.formInputs .loginInput {
  border-radius: 6px;
  border: 1px solid #72777a;
  display: flex;
  height: 40px;
  color: #72777a;
  font-size: 12px;
  line-height: 14px;
  padding-left: 10px;
}

.formInputs .loginInput:focus-within {
  border-color: var(--dark);
  color: var(--dark);
}

.formInputs .loginInput .countryCode {
  display: flex;
  align-items: center;
  border-right: 1px solid #d9d9d9;
  margin: 12px 0;
}

.formInputs .loginInput svg {
  margin: 0 3px;
}

.formInputs .loginInput input {
  border: none;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  border-radius: 6px;
  color: #72777a;
}

.formInputs .loginInput input:not(:placeholder-shown) {
  color: var(--dark);
}

.formInputs .loginInput input:focus {
  color: var(--dark);
}

.formInputs .loginInput input:focus-visible {
  border: none;
  outline: none;
}

.login__form .loginCta {
  border-radius: 6px;
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  margin-top: 50px;
  width: 100%;
  max-width: 260px;
  height: 30px;
}

/* LOGIN WITH OTP */

.login__form .mobConfirmation {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 23px;
}

.login__form .mobConfirmation h6 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: unset;
}

.login__form .formInnerWrapper {
  width: auto;
}

.resend {
  cursor: pointer;
  border: none;
  background: transparent;
  font-weight: 500;
  float: right;
  font-size: 14px;
  align-self: flex-end;
  color: var(--dark);
  margin-top: 8px;
}

.resend u {
  text-decoration: underline;
  color: grey;
}

/* REGISTER  */

.registerFormWrapper {
  padding-top: 50px;
  padding-bottom: 50px;
}

.registerFormWrapper .login__form .subText {
  margin-bottom: 20px;
}

.registerFormOuter {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.registerFormOuter::-webkit-scrollbar {
  width: 0;
  display: none;
}

.registerForm h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;
}

.registerForm .regIntro {
  color: #787878;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 20px;
}

.regFormGroup {
  position: relative;
  border-radius: 6px;
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.regFormGroup > label {
  position: absolute;
  font-size: 10px;
  line-height: 14px;
  text-transform: capitalize;
  background-color: var(--white);
  padding: 0 4px;
  left: 10px;
  top: -8px;
  z-index: 1;
}

.regFormGroup > input {
  border: 1px solid #72777a;
  border-radius: 6px;
  width: 100%;
  color: #72777a;
  font-size: 12px;
  line-height: 14px;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

.regFormGroup > input:focus {
  border-color: var(--dark);
  box-shadow: none;
  outline: none;
  color: var(--dark);
}

.regFormGroup > input:not(:placeholder-shown) {
  border-color: var(--dark);
  color: var(--dark);
}

.regFormGroup > input:disabled,
.regFormGroup > input:-internal-autofill-selected {
  background-color: var(--white) !important;
  color: #72777a;
}

.regFormGroup svg {
  position: absolute;
  right: 10px;
  margin: auto 0;
}

.regFormGroup .downArrow {
  left: 35px;
  right: unset;
}

.regFormGroup .separator {
  position: absolute;
  height: 16px;
  border-right: 1px solid #d9d9d9;
  left: 54px;
}

.registerErrors {
  color: #c03;
  font-size: 10px;
  font-weight: 300;
  line-height: 8px;
  margin-top: 8px;
  margin-bottom: 0;
}

.input__holder input::placeholder {
  color: #72777a;
}

.input__holder input::-moz-placeholder {
  color: #72777a;
}

.input__holder input::-webkit-input-placeholder {
  color: #72777a;
}

.input__holder {
  margin: 0;
  justify-content: center;
}

/* checkbox Css */

.login-checkbox-button {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.login-checkbox-button__input {
  cursor: pointer;
  width: 16px;
  height: 16px;
  appearance: none;
  position: relative;
  border: 1px solid var(--dark);
  border-radius: 3px;
}

.login-checkbox-button__input::before {
  content: '';
  display: grid;
  place-content: center;
  position: absolute;
}

.login-checkbox-button__input:checked {
  background-color: var(--wine);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--wine);
}

.login-checkbox-button__input:checked::before {
  background: url('/src/assets/icons/Check.svg') no-repeat center center;
  background-size: contain;
  width: 8px;
  aspect-ratio: 1/1;
  margin-bottom: 1px;
}

/* Checkbox Css Ends Here */

.signup_fotter_text {
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px;
  margin-bottom: 0;
  font-style: normal;
  margin-left: 10px;
}

.signup_fotter_text a,
.signup_fotter_text a:hover,
.signup_fotter_text a:focus {
  color: var(--dark);
}

.resend:disabled {
  cursor: not-allowed;
}

.resend-code {
  display: inline-block;
  min-width: 42px !important;
}

@media (max-width: 768px) {
  .login__form .register_amt {
    margin-bottom: 20px;
    white-space: normal;
  }
}

@media screen and (max-width: 600px) {
  .login {
    padding: unset;
    height: 100%;
  }

  .login__form img {
    width: 180px;
  }

  .login__form .subText {
    max-width: 354px;
  }

  .login__form {
    height: 100%;
  }

  .login__form .subText br {
    display: block;
  }

  .login__form .formInputs {
    justify-content: space-between;
    height: 100%;
  }

  .login__form .formInnerWrapper {
    width: unset;
  }

  .login__form .loginCta {
    max-width: unset;
    margin-top: 7px;
  }
}
