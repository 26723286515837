@import url(./loadFonts.css);
:root {
  /* Font Family */
  --font-family: 'Poppins', Arial, sans-serif;

  /* Colors */
  --dark: #000000;
  --white: #ffffff;
  --dark-2: #121214;
  --grey-1: #efefef;
  --grey-2: #787878;
  --grey-3: #e0e1e1;
  --grey-4: #6c6c6c;
  --choco: #906457;
  --light-grey: #d9d9d9;
  --light-bg: #f1f1f1;
  --grey-bg: #f8f8f8;
  --wine: #631011;

  /* Transition */
  --transition-timing: 1200ms;
  --transition-timing-function: ease-in-out;

  /* Screen Sizes */
  --media-min-sm: 320px;
  --media-max-sm: 480px;
  --media-min-md: 481px;
  --media-max-md: 768px;
  --media-min-lg: 769px;
  --media-max-lg: 1024px;
  --media-min-xl: 1025px;
  --media-max-xl: 1440px;
  --media-xs: 319px;
  --font-size: 16px;

  /* Animation */
  --shake-animation: shake 0.5s ease;
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-5px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateX(5px);
  }
}
