#landing-carousel {
  zoom: 90%;
}

.landing-carousal-bottom-gap {
  margin-bottom: 15px !important;
}

.homepageGrid {
  font-family: var(--font-family), sans-serif;
  padding: 0;
  text-align: center;
}

/* COMMUNITY */
.community {
  align-items: center;
}

.community .communityLanding {
  margin: 0;
}

.community .main-headings {
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
}

.community .landing-carousel img {
  width: 100%;
  min-height: 500px;
}

.landing-carousel-video-container .videosContainer {
  border-radius: 0px !important;
}

.community .landing-carousel .carousel-control-prev {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto 0;
  margin-left: 74px;
  opacity: 1;
}

.community .landing-carousel .carousel-control-next {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto 0;
  margin-right: 74px;
  opacity: 1;
}

.community .landing-carousel .carousel-indicators [data-bs-target] {
  width: 10px;
  height: 10px;
  border-radius: 8px;
  border: 1px solid var(--wine);
  background-color: transparent;
  opacity: 1;
}

.carousel-indicators {
  min-height: 5px;
}

@media screen and (min-width: 500px) {
  .categry .slick-slider {
    max-width: 93vw !important;
  }
}

.categry>div>div>div {
  display: flex;
  justify-content: center;
}

.categry>div>div>div>div {
  width: 100%;;
}

.community .landing-carousel .carousel-indicators .active {
  background-color: var(--wine);
}

.community .videoCard * {
  height: inherit;
  border-radius: 6px;
}

.community .videoCard {
  width: 308px;
  height: 507px;
}

.community .videoSection .slick-slide>div {
  padding: 0 8px;
}

.videoSection .slick-slide {
  max-width: 326px;
  max-height: 440px;
}

.community .homepage-sec-btn {
  display: block;
  width: 100%;
  border-radius: 6px;
  background-color: var(--white);
  font-weight: 400;
  text-transform: uppercase;
  color: var(--dark);
  border: 1px solid var(--dark);
  margin-top: 20px;
}

.community .homepage-sec-btn p {
  color: var(--dark);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}

.community .homepage-sec-btn div {
  height: 100%;
}

/* old Css */

.communityLanding {
  display: flex;
  margin-top: 0;
}

.communityLanding h1 {
  font-weight: 400;
  font-size: 44px;
  font-family: var(--font-family);
  line-height: 51px;
  text-transform: uppercase;
}

.communityLanding p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-transform: capitalize;
  margin-top: 31px;
  margin-bottom: 40px;
}

.cummunityCardTitle {
  font-family: var(--font-family);
  font-weight: 500;
  margin-top: 10px;
}

.communityLanding button {
  border: 1px solid #000000;
  border-radius: 5px;
  background-color: transparent;
  padding: 18px 48px;
  text-transform: capitalize;
  width: min-content;
  white-space: nowrap;
}

/* COMMUNITY CARDS */

.communityCardWrapper {
  color: var(--dark);
}

.communityCardWrapper>* {
  padding: 0;
}

.communityCardWrapper .slick-prev {
  top: 35% !important;
}

.communityCardWrapper .slick-next {
  top: 32% !important;
}

.cardpadd {
  padding: 0 8px;
}

.communityCard .cummunityCardTitle a {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  margin: 10px 0;
  text-transform: uppercase;
  color: var(--dark);
}

.communityCard .cummunityCardDesc {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-transform: capitalize;
  color: var(--dark);
  margin-bottom: 0;
}

.communityCard .cummunityCardDesc p {
  margin: 0;
  line-height: 28px;
}

.communityCard img {
  width: inherit;
  max-height: 262px;
  object-fit: cover;
  border-radius: 6px;
  width: 100%;
  aspect-ratio: 206/131;
}

.communityCard iframe {
  width: 100%;
  height: 262px;
  object-fit: contain;
}

.communityDetails .communityCard {
  padding: 0 14px;
}

.community_banner_image_holder {
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
  max-height: 80vh;
}

.community_banner_image_holder img {
  width: 100%;
  height: 480px;
  aspect-ratio: 16/9;
  object-fit: none;
}

.community #playBtn {
  position: absolute;
  inset: 0;
  object-fit: cover;
  background: rgb(11 11 11 / 3%);
  border: 10px;
}

.shmr {
  margin-top: 40px !important;
}

.cardYoutubePlayer {
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
  border-radius: 10px;
}

.landing {
  margin: 0px auto;
}

.communityInfo {
  padding-right: 20px;
  padding-left: 20px;
}

.communityDetaialsInfo {
  flex: 0.5;
}

.communityDetaialsImageSecond {
  flex: 0.5;
  height: 600px;
  object-fit: cover;
}

.communityContentextra {
  padding-top: 0px;
}

.communityDetaialsInfoSecond,
.communityContentextra {
  flex: 0.5;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.communityDetaialsImageSecondImg.top {
  float: right;
  padding: 0 0 0 20px;
}

.communityYoutubePlayer {
  width: 100%;
  aspect-ratio: 9/4;
  object-fit: contain;
}

.communityDetailsTrendingStoreis .communityCard {
  width: 100% !important;
}

.communityInfo {
  flex: 0.5;
}

.CommunityProducts-container {
  display: flex;
}

.shop-now {
  margin-top: 10px;
  height: 51px;
  width: 172px;
  background: #000000;
  border: none;
  color: var(--white);
  border-radius: 6px;
}

#communityHeadInfo {
  transition: all 2s;
}

.commpad {
  padding: 8px 0 15px 0;
  width: 100%;
}

.pdsm {
  padding-top: 40px;
}

.mb-5 {
  margin-bottom: 0rem !important;
}

.community .ytPlayBtn {
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  background-repeat: round;
}

.community .seasonalvideoCard {
  width: 414px;
  height: 262px !important;
}

.community .videoSection {
  margin-bottom: 40px;
}

.comm-det .pdr {
  text-align: left;
  margin: 0;
}

.comm-det .pdr h1 {
  font-size: 29px;
}

.comm-det .pdr p {
  text-align: left;
  padding: 0 20px 0 0;
  font-family: var(--font-family), sans-serif;
  font-size: 16px !important;
  margin: 0;
  line-height: 28px;
}

.communityCardInfo img {
  width: 100%;
}

.commdetytp p {
  text-align: left;
  margin-top: 20px;
  font-family: var(--font-family), sans-serif;
}

.communityContentextra p {
  font-family: var(--font-family), sans-serif;
  font-size: 16px;
  line-height: 28px;
}

/* Community Details*/
.communityDetails {
  align-items: center;
}

.communityDetailsHeroImage {
  width: 100%;
  height: 476px;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.communityDetails .main-headings {
  text-align: center;
  font-weight: 500;
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 40px !important;
  margin-top: 50px;
}

.communityDetaialsImage {
  /* object-fit: cover; */
  border-radius: 0;
  max-height: 500px;
  padding: 0 20px 0 0;
  width: 100%;
  height: 100%;
}

.communityDetails .homepageGrid {
  margin: 0;
  font-family: var(--font-family);
  padding: 0 20px;
}

.communityDetails .offer_banner img {
  width: 100%;
  height: 600px;
  min-height: 500px;
  object-fit: cover;
  margin: 0;
  padding-bottom: 15px;
}

.CommunityProducts-container {
  display: flex;
}

.communityYoutubePlayer {
  width: 100%;
  aspect-ratio: 13/7;
  object-fit: contain;
  margin-bottom: 40px;
}

.comm-det .videosContainer {
  aspect-ratio: 9/4 !important;
}

.communityDetails .comm-det {
  margin: 30px 0;
}

.communityDetails .CommunityProducts-container {
  display: flex;
  margin: 0 0 40px 0;
}

.commdet {
  text-align: center;
  padding: 50px 0 40px 0;
  font-family: var(--font-family);
  font-size: 30px;
}

.commpad {
  padding: 8px 0;
}

.communityDetails .videoCard {
  width: 308px;
  height: 507px;
}

.communityDetails .videoCard * {
  height: 100%;
  border-radius: 6px;
}

.communityDetails .ytPlayBtn {
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  background-repeat: round;
}

.communityDetails .communityCard img {
  width: 100%;
  height: 262px;
  object-fit: cover;
  border-radius: 6px;
}

.communityDetails .communityDetaialsImageSecondImg {
  width: 100%;
  height: 564px;
  object-fit: cover;
  float: left;
  padding: 0 20px 0 0;
  border-radius: 0px;
  max-width: 769px;
}

.d-cont {
  display: contents;
}

/* Old css */
.communityDetails .landing h1 {
  font-weight: 400;
  font-size: 29px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.communityContentextra {
  font-family: var(--font-family);
  margin: 40px 0;
}

.communityDetaialsInfoSecond {
  font-family: var(--font-family);
  margin: auto;
}

.communityDetails .landing p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-transform: capitalize;
}

.communityDetails .productCardCta {
  background: var(--dark);
  border-radius: 6px;
  padding: 12px 36px;
  font-size: 18px;
  text-transform: uppercase;
  color: var(--white);
}

.communityDetails,
.communityDetails p {
  font-family: var(--font-family);
  margin: 0;
  font-size: 16px;
}

.cummunityCardTitle {
  font-size: 18px;
  font-weight: 500;
  font-family: var(--font-family);
  margin-top: 10px;
}

.cummunityCardDesc {
  font-size: 15px;
  font-family: var(--font-family);
}

.communityContentextra {
  margin: 4px 0 0 0 !important;
  text-align: justify;
}

.commdetytp p {
  margin-bottom: 20px !important;
}

.commdetytp p:last-child {
  margin-bottom: 0 !important;
}

.communityDetaialsInfo {
  font-family: var(--font-family);
}

.comm-padd {
  margin: 0 0 70px 0;
}

.comm-dt {
  font-size: 30px;
  line-height: 38px;
}

.cdpad {
  padding: 0 8px;
  height: 570px;
}

.imgal {
  display: flex;
  justify-content: end;
}

.communityDetails .videoSection {
  margin: 0 0 40px 0;
}

@media screen and (min-width: 1440px) {
  .community .homepageGrid {
    width: 1294px;
    text-align: center;
  }

  .communityDetails .homepageGrid {
    width: 1294px;
    padding: 0;
  }
}

/* HOMEPAGE HEADINGS */

.heading-wrapper {
  min-height: 26px;
  contain: content;
}

.main-headings {
  text-transform: uppercase;
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0.12em;
  text-align: center;
  display: block;
}

/* HOMEPAGE BUTTONS */

.homepage-sec-btn {
  display: block;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  margin-top: 13px;
}

.homepage-sec-btn:hover {
  color: inherit;
}

.catgry-landng-pge .homeOffers .homepage-sec-btn {
  margin-top: 12px;
}

.homepage-sec-btn div {
  height: 100%;
}

.homepage-sec-btn p {
  margin-bottom: 0;
  height: 100%;
}

.homepage-sec-btn a {
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.homepage-sec-btn:hover {
  color: inherit;
}

/* LANDING CAROUSEL */

.landing-carousel {
  margin-bottom: 50px;
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
}

.landing-carousel .carousel-indicators [data-bs-target] {
  width: 20px;
  height: 5px;
  border: unset;
  border-radius: 3px;
  opacity: 1;
  background-color: #f0f0f0;
}

.landing-carousel .carousel-indicators .active {
  width: 40px;
  background-color: var(--wine);
}

.landing-carousel .carousel-item a>* {
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin-bottom: 27px;
}

.productListing.carousel-item {
  min-height: calc(70vh - 100px);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.landing-carousel {
  max-width: inherit;
}

.landing-carousel .carousel-control-prev {
  width: fit-content;
  height: fit-content;
  margin: auto 0;
  margin-left: 74px;
  opacity: 0.6;
}

.landing-carousel .carousel-control-prev:hover {
  opacity: 1;
}

.landing-carousel .carousel-control-next {
  width: fit-content;
  height: fit-content;
  margin: auto 0;
  margin-right: 74px;
  opacity: 0.6;
}

.landing-carousel .carousel-control-next:hover {
  opacity: 1;
}

.landing-carousel .carousel-control-prev-icon {
  background-color: var(--white);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding: 5px;
  content: url('assets/icons/left-arrow.svg');
  background-image: none;
}

.landing-carousel .carousel-control-next-icon {
  background-color: var(--white);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding: 9px;
  content: url('assets/icons/right-arrow.svg');
  background-image: none;
  height: 39px;
  width: 40px;
  border-radius: 50%;
  background-color: #fff;
}

/* HOME OFFERS */

.homeOffers .slick-track {
  display: flex;
}

.homeOffers .slick-list {
  margin: 0 -8px;
}

.homeOffers .slick-slide>div {
  width: 100%;
  padding: 0 4px;
}

.homeOffers .slick-track>div {
  max-width: 335px;
}

.homeOffers .linkWrapper>* {
  border-radius: 6px;
  max-height: 440px;
  min-height: 258px;
  object-fit: cover;
  aspect-ratio: 8/11;
}

.homeOffers .linkWrapper:focus {
  outline: none;
}

/* EXCLUSIVES */

.exclusives img {
  aspect-ratio: 14/15;
  object-fit: fill;
  width: 100%;
  max-height: 450px;
  border-radius: 10px;
}

.exclusives .slick-slide>div {
  padding: 0 10px;
}

.slick-slider .customDots {
  bottom: -31px;
}

.slick-slider .customDots li {
  height: 5px;
  margin: 0 2.5px;
}

.slick-slider .customDots .slick-active {
  width: 40px;
}

.slick-slider .customDots li button:before {
  width: 20px;
  height: 5px;
  content: ' ';
  opacity: 1;
  background-color: #f0f0f0;
  border-radius: 3px;
}

.slick-slider .customDots li.slick-active button:before {
  background-color: var(--wine);
  opacity: 1;
  width: 40px;
}

/* SECONDARY CAROUSEL */

.secCarousel .slick-slide>div {
  padding: 0 8px;
}

.secCarousel a img {
  width: 100%;
  height: 100%;
  aspect-ratio: 313/370;
  max-height: 370px;
}

/* SLIDER WITH TABS */

.productSlider .main-headings {
  margin-bottom: 10px;
}

.tabSlider .slick-list {
  margin: 0;
}

.secCarousel .slick-list {
  margin-bottom: 20px;
}

.exclusives .slick-list {
  margin-bottom: 20px;
}

.offerHighlights .slick-list,
.offer-content .slick-list {
  margin-bottom: 20px;
}

#b2b-banner_homepage,
.stripe-slider {
  margin-top: 6px;
}

.gb-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.gb-tabs a {
  text-decoration: none;
  color: var(--dark);
  padding: 0px 14px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0.12em;
}

.gb-tabs a:first-child {
  border: none !important;
}

.gb-tabs a:nth-child(n) {
  border: none;
  border-left: 2px solid var(--dark);
}

.gb-tabs .active {
  font-weight: 500;
}

.productSlider .slick-track,
.homeOffers .slick-track,
.blogSection .slick-track,
.videoSection .slick-track,
.offerSlider .slick-track {
  display: flex;
  justify-content: center;
}

.productSlider {
  padding-right: 3px;
}

.homeOffers .slick-next {
  top: 48% !important;
}

.productSlider .slick-next {
  top: 47.4% !important;
}

.productDetailPage .frqnt-prod {
  max-width: 100%;
}

.oneProductOnBestseller div {
  max-width: 340px;
}

.oneProductOnBestseller {
  display: grid;
  place-items: center;
  grid-template-columns: 1fr;
}

@media (max-width: 600px) {
  .two-products-in-slider .tabSlider .slick-list {
    border-right: 1px solid #d9d9d9;
    border: none !important;
    overflow: visible !important;
  }

  .catgry-landng-pge .two-products-in-slider .tabSlider .slick-list .productCard {
    box-shadow: none !important;
    padding-bottom: 10px !important;
    border: 1px solid #d9d9d9 !important;
    margin-right: -1px;
  }

  .catgry-landng-pge .two-products-in-slider .tabSlider .slick-list .slick-active {
    border: none !important;
  }

  .one-product-in-slider .tabSlider .slick-slide>div>div {
    display: inline-block !important;
    max-width: 180px !important;
  }

  .one-product-in-slider .productCard {
    box-shadow: none !important;
    border: 1px solid #d9d9d9;
  }

  .one-product-in-slider .products-slider-wthout-slide .slick-active {
    border: none !important;
  }

  .recently-viewed-productcard-wrapper {
    width: 180px !important;
  }

  .productCard {
    max-width: 340px !important;
  }

  .frqnt-prod .tabSlider .slick-slide {
    padding-top: 0px;
  }

  .productDetailPage .frqnt-prod-one-product .slick-slide {
    padding-top: 0.6px;
    border-bottom: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;
  }

  .productDetailPage .recent {
    padding: 0 !important;
    margin: 0;
  }

  .oneProductOnBestseller div {
    max-width: 164px;
  }
}

/* LOOKS SECTION  */

.looksModal .modal-dialog {
  max-height: 402px !important;
  max-width: 800px !important;
}

.looks-section .slick-vertical {
  height: 717px;
}

.looks-section .slick-vertical .slick-slide {
  margin: 6px 0;
}

.left-slider .slick-list {
  height: 100%;
}

.looks-section .left-slider {
  display: flex;
  width: 11%;
  padding-left: 0;
  padding-right: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
}

.looks-section .left-slider img {
  object-fit: cover;
  cursor: pointer;
  aspect-ratio: 1/1;
}

.b2b-landing-overlay {
  position: fixed !important;
}

.overlay {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 5, 5, 0.7);
  z-index: 1;
}

.looks-section .left-slider .thumbnailPlay {
  position: absolute;
  left: 40%;
  z-index: 2;
}

.looks-section .left-slider .in-focus {
  padding: 1px;
  border: 2px solid var(--dark);
}

.look-of-the-day-section {
  width: 100%;
}

.look-of-the-day-poster {
  height: fit-content;
}

.looks-section .left-slider .slick-track .slick-slide:first-child {
  margin-top: 0;
}

.looks-section .left-slider .slick-track .slick-slide:last-child {
  margin-bottom: 0;
}

.looks-section .center-slider {
  padding-left: 16px;
  padding-right: 0;
  width: 49%;
  overflow: hidden;
  aspect-ratio: 1/1;
  height: 100%;
}

.looks-section .center-slider * {
  height: 100%;
}

.looks-section .right-slider {
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 42px;
  width: 40%;
  font-size: 16px;
}

.looks-section .right-slider h3 {
  font-weight: 500;
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 18px;
}

.looks-section .right-slider p {
  margin-bottom: 0;
}

.looks-section .right-slider .description {
  font-family: var(--font-family), sans-serif;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 26px;
  font-size: 14px;
}

.looks-section .right-slider button {
  width: 100%;
  padding: 12px 0;
  border-radius: 5px;
}

.looks-section .right-slider button a {
  color: var(--dark);
  line-height: 14px;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  display: block;
  width: 100%;
}

.looks-section .slick-arrow {
  width: 100% !important;
  background: #0303038f !important;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
}

.looks-cover-img {
  height: 100%;
  object-fit: cover;
}

/* BLOGS */
.blogSection .slick-prev {
  top: 42% !important;
}

.blogSection .slick-next {
  top: 40% !important;
}

.blogSection .slick-list {
  margin: 0 -8px;
}

.blogSection .slick-slide>div {
  padding: 0 4px;
}

.blogCards div {
  object-fit: cover;
  width: 100%;
  margin-bottom: 10px;
}

.blogCards div>* {
  width: 100%;
  border-radius: 6px;
  max-height: 440px;
  object-fit: cover;
  aspect-ratio: 16/25;
}

.blogCards h4 {
  font-weight: 500;
  color: var(--dark);
  font-size: 16px;
  line-height: 26px;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 56px;
  line-height: 28px;
  padding: 0;
  margin: 0;
}

/* VIDEO SECTION */

.videoSection .slick-list {
  margin: 0 -8px;
}

.videoSection .slick-slide>div {
  padding: 0 4px;
}

.videoCard>* {
  width: 100%;
  max-height: 497px;
  border-radius: 6px;
  object-fit: cover;
}

/* BRAND STORE */

.bsimg {
  width: 100%;
  text-align: left;
}

.bswelsec {
  padding: 133px 77px;
  background: #f6f6f6;
}

.recent .slick-track {
  max-height: 557px;
}

.four-slider-wraper .slick-track {
  max-height: 555px;
}

@media screen and (min-width: 991px) and (max-width: 1200px) {
  .bswelsec {
    padding: 66px 77px !important;
  }
}

@media screen and (max-width: 991px) {
  .gb-tabs {
    margin-bottom: 20px;
    row-gap: 10px;
  }
}

.bsbk {
  background: #f6f6f6;
  padding: 0;
}

.bswel {
  font-family: var(--font-family);
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
}

.bsp {
  font-size: 16px;
  font-family: var(--font-family);
  text-align: center;
  text-transform: uppercase;
}

.bshead {
  margin: 8px 0 40px 0;
  text-align: center;
  font-size: 30px;
  text-transform: uppercase;
}

.bsshop {
  width: 100%;
  aspect-ratio: 2/1;
  border-radius: 6px;
}

.bsgi {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.bspor {
  width: auto;
  position: relative;
  margin-bottom: 4px;
  padding: 4px;
  text-align: center;
}

.bspoa {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #fff;
  border-radius: 6px;
  color: #fff;
}

.bs-tit {
  width: max-content;
  padding: 17px 41px;
  margin: 0;
  font-family: var(--font-family);
  font-size: 24px;
  text-transform: uppercase;
}

.offerGrid {
  max-width: 1270px;
  margin: 0 auto;
  font-family: var(--font-family);
  font-size: 16px;
  color: var(--dark);
  margin-bottom: 50px;
}

.offerGrid.slick-slide>div {
  padding: 0 4px;
}

.breadcrumbWrapper {
  padding-top: 26px;
  padding-bottom: 44px;
}

.breadcrumbWrapper a {
  color: #a7a6a6;
}

.breadcrumbWrapper .breadcrumb-item.active {
  color: var(--dark);
}

.offerGrid .carousel {
  max-width: 1414px;
}

.offerHighlights a {
  display: flex !important;
  flex-direction: column;
  gap: 16px;
}

.offerHighlights a .mediaElm {
  object-fit: fill;
  width: 100%;
  aspect-ratio: 42/25;
}

.offerHighlights a h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.07em;
  text-align: center;
  color: var(--dark);
  text-transform: uppercase;
}

.offerHighlights .slick-slide>div {
  padding: 0 10px;
}

.offerSlider .slick-slide>div {
  padding: 0 10px;
}

.offerSlider .sliderImg {
  width: 100%;
  aspect-ratio: 42/29;
  max-height: 290px;
  object-fit: fill;
  border-radius: 10px;
}

.offer_banner {
  max-width: 1600px;
  margin: 0 auto;
}

.offer_banner img {
  width: 100%;
  object-fit: fill;
  margin-bottom: 0;
}

.breadcrumbWrapper {
  display: none;
}

.brandLandingWrapper {
  font-family: var(--font-family);
  max-width: 1440px;
  margin: 0 auto;
}

.brandLandingWrapper .brand-cont {
  font-weight: 400;
}

.brandproduct .slick-prev {
  top: 43% !important;
}

.brandproduct .slick-next {
  top: 43% !important;
}

.brandproduct .slick-slider {
  padding: 0;
}

.brandproduct .slick-track {
  display: flex;
  gap: 8px;
}

.brand-prod {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 13/10;
}

.brand-prod:focus {
  outline: -webkit-focus-ring-color auto 1px !important;
}

.brand-pro:focus-visible {
  outline: 0px;
}

.categorySlideContent {
  display: flex !important;
  flex-direction: column;
  gap: 10px;
}

.brand-heading-wrapper {
  min-height: 16px;
  contain: content;
  width: 100%;
}

.brand-cont-txt {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  display: block;
  max-width: 100%;
  margin-bottom: 0;
}

.selectedCircle {
  border: 1px solid rgb(14, 13, 13);
  padding: 5px;
  user-select: none;
}

.brand-feature .sliderImg {
  width: 100%;
  object-fit: contain;
  max-height: 332px;
  aspect-ratio: 1/1;
}

.brnd-name {
  text-align: center;
  color: var(--bs-body-color);
  font-size: 22px;
  margin-top: 28px;
  text-transform: capitalize;
}

.brnd .brndban {
  justify-content: center !important;
}

.communityDetaialsImageSecondImg {
  border-radius: 0;
  float: left;
  max-width: 50%;
  padding: 0 20px 0 0;
}

@media screen and (min-width: 478px) {
  .looks-section .left-slider img {
    min-height: 141px;
  }
}

@media screen and (max-width: 1414px) {
  .offerGrid {
    padding: 0 16px;
    margin: 0 auto;
    margin-bottom: 30px;
    width: 100%;
    max-width: 80%;
  }

  .breadcrumbWrapper {
    display: none;
  }

  .offerSlider .slick-slide>div {
    padding: 0 4px;
  }
}

/* RESPONSIVE SETTINGS */

@media screen and (max-width: 1440px) {
  .homepageGrid {
    padding: 0 20px;
  }
}

@media screen and (max-width: 1250px) {
  .communityCard img {
    width: 100%;
  }

  .community .seasonalvideoCard {
    width: 100%;
  }
}

@media screen and (min-width: 425px) and (max-width: 720px) {
  .community .videoCard {
    height: 350px !important;
  }

  .communityDetails .videoCard {
    height: 350px;
  }

  .communityDetails .communityDetaialsImageSecondImg {
    padding: 0 0 20px 0;
  }
}

@media screen and (min-width: 1150px) {
  .products-slider-wthout-slide .slick-slide {
    margin-bottom: 12px;
    border-bottom: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .looks-section .right-slider .description {
    font-size: 10px;
  }
}

.buy-again-slider-single-prod .productCard,
.one-product-in-slider .productCard {
  border: 1px solid #d9d9d9;
  box-shadow: none;
}

@media screen and (min-width: 600px) and (max-width: 990px) {
  .communityDetailsHeroImage {
    aspect-ratio: 0;
  }

  .comm-det .pdr {
    padding: 0 20px 30px 0;
  }

  .pdg-0 {
    padding: 0;
  }

  .comm-det-tit {
    margin: 20px 0;
  }

  .communityDetails .landing {
    margin: 0 auto 40px auto;
  }

  .communityDetails .comm-det {
    margin-top: 20px;
    margin-bottom: 0;
  }

  .communityDetaialsInfoSecond {
    font-size: 14px;
  }

  .comm-dt {
    font-size: 20px;
    line-height: 25px;
    padding: 0;
    margin: 0 0 10px 0;
  }

  .communityDetails .comm-det-tit {
    margin: 20px 0;
  }

  .communityDetails,
  .communityDetails p {
    font-size: 14px;
    padding: 0;
  }

  .cdpad {
    height: 510px;
  }
}

@media screen and (max-width: 990px) {
  .productDetailPage .recent {
    padding-bottom: 20px !important;
  }

  .brandproduct {
    margin-bottom: 0%;
    min-height: 100px;
  }

  .comm-det .pdr p p {
    margin: 10px 0;
  }

  .looksProductCard .fPrice,
  .looksProductCard .rPrice,
  .looksProductCard .dis {
    font-size: 13px !important;
  }

  .community .landing-carousel img {
    height: 500px;
  }

  .community {
    padding: 0;
  }

  button#communityReadMore {
    width: 100%;
  }

  .community .carousel-inner {
    padding: 0;
  }

  .mpd {
    padding: 0 !important;
  }

  .mb-5 {
    margin-bottom: 0rem !important;
  }

  .community .landing-carousel .carousel-control-next {
    display: none;
  }

  .community .landing-carousel .carousel-control-prev {
    display: none;
  }

  .communityDetaialsInfoSecond,
  .communityContentextra {
    padding: 0px !important;
  }

  .communityDetaialsImage {
    margin-bottom: 0 !important;
  }

  .communityContentextra p:last-child {
    margin-bottom: 0;
  }

  .communityContentextra {
    margin-bottom: 0 !important;
  }

  .comm-det .pdr {
    padding: 0;
  }

  .comm-det .pdr h1 {
    font-size: 18px !important;
  }

  .comm-det .pdr p {
    font-size: 14px !important;
    width: 100%;
    padding: 0;
    text-align: justify;
  }

  .communityContentextra p {
    font-size: 14px;
  }

  .blogCards div {
    height: auto;
    font-size: 14px;
  }

  .main-headings {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .homepage-sec-btn {
    font-family: var(--font-family), sans-serif;
    font-size: 13px;
    height: 40px;
    margin-top: 20px;
  }

  .homepage-sec-btn a {
    font-family: var(--font-family), sans-serif;
    font-size: 13px;
  }

  .landing-carousel .carousel-inner {
    padding: 0;
  }

  .homeOffers .slick-list {
    margin: 0;
  }

  .exclusives img {
    aspect-ratio: 61/70;
  }

  .exclusives .slick-current {
    margin-right: unset !important;
  }

  .gb-tabs a {
    font-size: 14px;
  }

  .tabSlider .slick-list {
    margin: 0;
  }

  .blogSection .slick-list {
    margin: 0;
  }

  .blogCards h4 {
    font-family: var(--font-family), sans-serif;
    font-size: 14px;
    padding: 0 10px;
    line-height: 20px;
  }

  .looks-section .left-slider {
    width: 100%;
    height: auto;
    padding-right: 0;
    margin-bottom: 16px;
    gap: unset;
  }

  .looks-section .left-slider>* {
    width: 24%;
    min-height: unset;
  }

  .looks-section .center-slider {
    width: 100%;
    height: auto;
  }

  .looks-section .right-slider {
    padding: 10px 0px;
    background: #ffffff;
    width: 100%;
  }

  .looks-section .right-slider .right-slider-container {
    width: 100%;
  }

  .looks-section .right-slider button {
    margin-top: 4px;
    padding: 10px;
  }

  .looks-section .right-slider button a {
    font-size: 13px;
    font-family: var(--font-family), sans-serif;
    padding: 8px;
  }

  .looks-section .left-slider .slick-track {
    width: 100% !important;
    display: flex;
    justify-content: space-between;
  }

  .looks-section .left-slider .slick-slide {
    width: 100% !important;
  }

  .looks-section .left-slider .slick-track {
    gap: 8px;
  }

  .looks-section .left-slider .slick-track::before,
  .looks-section .left-slider .slick-track::after {
    display: none;
  }

  .videoSection .slick-list {
    margin: 0;
  }
}

@media screen and (max-width: 820px) {
  .comm-padd {
    margin: 20px 0;
  }

  .communityDetails .main-headings {
    margin-bottom: 20px !important;
    margin-top: 20px;
    font-size: 18px;
  }

  .communityDetails {
    align-items: inherit;
  }

  .cdpad {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .landing-carousel .carousel-item a>* {
    aspect-ratio: 21/25;
    height: 100%;
  }

  .homeOffers .linkWrapper>* {
    object-fit: fill;
    aspect-ratio: 7/11;
  }

  .offerHighlights>div {
    flex-direction: column;
  }

  .communityDetaialsImageSecondImg {
    max-width: 100%;
    padding: 0px;
  }

  .bs-tit {
    font-size: 14px;
    padding: 8px 20px;
  }

  .iwd {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 300px) and (max-width: 600px) {
  .community .videoCard {
    height: 300px;
  }

  .communityCard img {
    object-fit: cover;
    aspect-ratio: 7 / 11;
    max-height: 250px;
  }

  .communityDetails .main-headings {
    margin-bottom: 20px !important;
    margin-top: 20px;
    font-size: 18px;
  }

  .CommunityProducts-container .w-25 {
    width: 50% !important;
  }

  .communityDetails .pdr {
    padding: 0;
    margin-bottom: 20px;
  }

  .pdg-0 {
    padding: 0;
  }

  .communityDetails .communityDetaialsImageSecondImg {
    height: auto;
    padding: 0 0 20px 0;
  }

  .communityDetails .comm-det-tit {
    margin: 20px 0;
  }

  .comm-dt {
    padding: 10px;
  }

  .communityDetails,
  .landing {
    display: flex;
    flex-direction: column;
  }

  .comm-det,
  .landing {
    display: flex;
    flex-direction: column;
  }

  .communityDetaialsImage {
    height: auto;
    aspect-ratio: auto;
  }

  .communityDetaialsImageSecondImg {
    height: auto;
    max-height: 411px;
    aspect-ratio: auto;
  }

  .communityDetaialsImageSecondImg.top {
    padding: 0;
  }

  .communityDetails .landing h1 {
    font-size: 29px;
    line-height: 40px;
    margin-bottom: 10px;
  }

  .communityDetails .landing p {
    font-size: 14px;
    line-height: 1.6;
    text-align: justify;
  }

  .landing {
    margin: 0;
  }

  .communityContent {
    display: flex;
    flex-direction: column;
  }

  .communityDetaialsInfoSecond,
  .communityContentextra {
    padding: 10px;
  }

  .communityDetaialsImageSecond {
    width: 100%;
    height: 450px;
    object-fit: cover;
    border-radius: 6px;
    max-width: 396px;
  }

  .communityDetails .CommunityProducts-container {
    display: flex;
    margin: 0 0 20px 0;
  }

  .mpd {
    padding: 0 !important;
  }

  .communityDetails .videoSection {
    padding: 0;
    margin: 0 0 20px 0;
  }

  .communityYoutubePlayer {
    margin-bottom: 20px;
  }

  .communityDetails .communityCard {
    padding: 0 !important;
  }

  .communityDetails .videoCard {
    height: 300px;
  }

  .cdpad {
    height: auto;
    padding: 0;
  }

  .communityDetails,
  .communityDetails p {
    font-size: 14px;
    padding: 0px;
  }
}

@media screen and (max-width: 600px) {
  .communityLanding {
    flex-direction: column-reverse;
  }

  .community_banner_image_holder {
    width: 100%;
    object-fit: cover;
  }

  .communityInfo h1 {
    font-size: 25px;
    padding: 0 15px;
  }

  .communityInfo p {
    font-size: 14px;
    padding: 0 10px;
    margin: 10px 0;
  }

  .communityInfo a {
    margin: 10px auto;
  }

  .pdsm {
    padding-top: 20px;
  }

  .shmr {
    margin: 20px 0 0 0;
  }
}

@media screen and (max-width: 576px) {
  .looks-section .left-slider .slick-track {
    gap: 8px;
  }

  .looks-section .left-slider .slick-slide {
    width: 100% !important;
  }

  .communityInfo {
    padding-right: 0;
    padding-left: 0;
  }

  .crm {
    margin: 0 10px;
  }
}

@media screen and (max-width: 992px) {
  .productListing .carousel-item {
    min-height: calc(0vh - 100px);
  }

  .homeOffers .slick-slide>div {
    padding: 0 3px !important;
  }

  .looks-section .center-slider {
    padding-left: 0;
  }

  .blogSection .slick-slide>div {
    padding: 0 3px;
    justify-content: center;
  }

  .videoSection .slick-slide>div {
    padding: 0 3px;
  }

  .offerSlider .sliderImg {
    aspect-ratio: 61/70;
    max-height: unset;
    border-radius: unset;
  }

  .offerHighlights a .mediaElm {
    aspect-ratio: 19/30;
  }
}

@media screen and (max-width: 992px) {
  .reverse-div {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 850px) {

  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .recent .slick-track {
    max-height: 409px;
  }

  .recent .tabSlider {
    margin: 0 !important;
  }

  .recent .slick-list {
    border: 1px solid #d9d9d9;
    border-top: 0.5px solid #d9d9d9;
  }

  .productDetailPage .recent .slick-list {
    border: 1px solid #d9d9d9;
    margin-left: -1px;
  }

  .productDetailPage .recent .product-slick-slider {
    border: none !important;
  }

  .productDetailPage .recent .slick-slide .productCard {
    border-top: none !important;
  }

  .productDetailPage .frqnt-one-prod .slick-slide .productCard {
    border-top: 1px solid #d9d9d9 !important;
  }

  .frqnt-one-prod .slick-list {
    overflow: visible;
    border: none;
  }

  .frqnt-two-prod {
    overflow: visible;
  }

  .frqnt-one-prod .productCard {
    margin-bottom: 0;
  }

  .recent .products-slider-wthout-slide .slick-current {
    border: 0 !important;
  }

  .recent .products-slider-wthout-slide .slick-active {
    border: 0 !important;
  }
}

@media screen and (max-width: 426px) {
  .comm-det .pdr h1 {
    margin-top: 20px;
  }

  .community .videoCard {
    height: 280px;
  }

  .community .homepage-sec-btn {
    margin-top: 20px !important;
  }

  .cummunityCardTitle {
    font-size: 14px;
  }

  .community .main-headings {
    font-size: 18px;
  }

  .communityDetails .slick-slide>div {
    padding: 0 3px !important;
  }

  .communityDetails .landing h1,
  .comm-dt {
    font-size: 18px !important;
    line-height: 25px !important;
  }

  .communityDetails .comm-det {
    margin: 10px 0 0 0 !important;
  }

  .communityDetails .videoSection {
    margin: 0 0 20px 0;
  }

  .communityDetails .communityDetaialsImageSecondImg {
    padding: 0 0 20px 0;
  }
}

@media screen and (min-width: 720px) and (max-width: 1020px) {
  .community .videoCard {
    height: 380px;
  }

  .communityDetails .videoCard {
    height: 380px;
  }

  .communityDetails .communityDetaialsImageSecondImg {
    padding: 0 0 20px 0;
  }
}

@media screen and (max-width: 426px) {
  .comm-det .pdr p p {
    margin: 10px 0;
    width: 100%;
  }

  .brandproduct .slick-next {
    display: none !important;
  }
  
}

@media screen and (max-width: 992px) {
  .bswelsec {
    padding: 20px;
  }

  .bswel {
    font-size: 18px;
  }

  .bsp {
    font-size: 12px;

    text-align: left;
  }

  .bshead {
    font-size: 14px;

    margin: 20px 0;
  }
}

@media screen and (max-width: 376px) {

  .homeOffers .slick-slide img,
  .blogCards img,
  .videoCard {
    height: 250px;
  }

  .community .videoCard {
    height: 250px;
  }
}

@media screen and (max-width: 360px) {
  .community .videoCard {
    height: 240px !important;
  }

  .communityDetails .videoCard {
    height: 240px !important;
  }
}

@media screen and (min-width: 720px) and (max-width: 1020px) {
  .community .videoCard {
    height: 380px;
  }
}

@media screen and (max-width: 400px) {
  .productDetailPage .recent {
    margin: 0;
    padding: 0 11px;
  }
}

@media screen and (max-width: 320px) {
  .community .videoCard {
    height: 220px;
  }

  .looksProductCard .checkbox-button__control:before {
    width: 16px !important;
    height: 16px !important;
  }

  .looksProductCard .checkbox-button__input:checked+.checkbox-button__control:after {
    margin-left: 5px !important;
  }

  .looksModal .modalCta p,
  .looksProductCard p {
    font-size: 12px !important;
  }

  .looksProductCard .fPrice,
  .looksProductCard .rPrice,
  .looksProductCard .dis {
    font-size: 10px !important;
  }

  .looksModal .modalCta button {
    width: 152px !important;
    font-size: 14px !important;
  }

  .homeOffers .slick-slide img,
  .blogCards img,
  .videoCard {
    height: 220px;
  }
}

.looksModal {
  font-family: var(--font-family);
  overflow: hidden;
}

.looksModal .looksModalHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #787878;
  padding-bottom: 5px;
  align-items: center;
}

.looksModal .looksModalHeader .modal-title {
  font-size: 18px;
  font-family: poppins;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
}

.looksModal .looksModalHeader button {
  background: none;
  border: none;
}

.looksModal .modal-dialog {
  max-width: 720px;
}

.looksModal .modalImg {
  aspect-ratio: 1/1;
  max-height: 80px;
  border-radius: 3px;
}

.looksModal .modalCta {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.looks-modal-header {
  padding-top: 13px;
  padding-left: 20px;
  padding-right: 20px;
}

.looks-modal-body {
  padding-top: 10px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-bottom: 20px;
}

.look-modal-footer-section {
  margin-top: 16px;
  height: 64px;
  display: flex;
  justify-content: center;
}

.looksModal .modalCta p {
  margin-bottom: 10px;
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: unset;
}

.looksModal .modalCta button {
  margin-top: 7px;
  border-radius: 6px;
  height: 40px;
  width: 260px;
  font-size: 12px;
  line-height: 13px;
  font-weight: 400;
  text-transform: uppercase;
}

.looks-Wrapper {
  position: relative !important;
}

.productsWrapper {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-auto-rows: min-content;
  column-gap: 10px;
  row-gap: 16px;
  display: grid;
  max-height: 336px;
  overflow-y: scroll;
  margin-top: 26px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.looks-Wrapper {
  max-height: 255px;
  margin-top: 0;
  column-gap: 20px !important;
  row-gap: 18px !important;
}

@media screen and (max-width: 576px) {
  .productsWrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .looks-Wrapper {
    max-height: 350px;
  }
}

@media screen and (max-width: 320px) {
  .looksProductCard .checkbox-button__input:checked+.checkbox-button__control:after {
    transform: translate(-19px, -4px);
  }
}

.productsWrapper::-webkit-scrollbar {
  width: 0.1em;
}

.productsWrapper::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.looksProductCard {
  height: 90px;
  background: #f5f5f5;
  display: flex;
  padding: 10px;
  gap: 18px;
  align-items: center;
  border-radius: 3px;
}

.looksProductCard h4 {
  font-family: Poppins;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: 13px;
  margin-bottom: 0;
}

.looksProductCard .checkbox-button {
  max-height: 16px;
  display: inline-block;
}

.looksProductCard .checkbox-button__input {
  position: absolute;
  opacity: 0;
}

.looksProductCard .checkbox-button__control {
  position: relative;
}

.looksProductCard .checkbox-button__control::before {
  content: ' ';
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  background-color: var(--white);
  border: 1px solid var(--dark);
  border-radius: 3px;
  cursor: pointer;
}

.looksProductCard .checkbox-button__input:checked+.checkbox-button__control::before {
  background-image: url('../../assets/icons/LooksModal/check-box-with-tick.svg');
  border: none !important;
}

.looksProductCard p {
  margin-bottom: 6px;
  color: #686767;
  margin-top: 3px;
  font-size: 11px;
  line-height: 16px;
  text-transform: capitalize;
  font-weight: 300;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.looksProductCard .fPrice {
  color: #000;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
}

.looksProductCard .rPrice {
  color: #b6b6b6;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-decoration: strikethrough;
  text-transform: capitalize;
}

.looksProductCard .dis {
  color: #77353b;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  text-transform: capitalize;
}

.looksProductCard .imgWrapper {
  background-color: var(--white);
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 3px;
}

.looksProductCard .imgWrapper img {
  width: 70px !important;
  height: 70px !important;
  object-fit: contain;
  border-radius: 3px;
}

.col-siz-container {
  margin-top: 6px;
  align-items: center;
  gap: 4px;
}

.siz-container {
  margin-left: -12px;
}

.prod-col-img-holder {
  margin-top: 1px;
  height: 8px;
  width: 8px;
  border-radius: 50px;
}

.prod-col-img-label {
  line-height: 0;
  font-family: Poppins;
  font-size: 9px;
  font-weight: 300;
  letter-spacing: 0em;
  text-align: left;
}

.prod-col-img-holder img {
  position: absolute;
  height: 8px;
  width: 8px;
  border-radius: 50px;
}

.color-img-not-fount {
  display: none;
}

.product-slick-slider {
  border: 1px solid #d9d9d9;
}

.products-slider-wthout-slide .slick-active {
  border-right: 0;
  border-left: 0;
  justify-content: center;
}

.looks-modal-open {
  padding-right: 7px !important;
}

#navbar-top-desktop {
  display: block !important;
  position: fixed !important;
  width: 100% !important;
  top: 0 !important;
  z-index: 1020 !important;
}

.faqSlider .accordian-item {
  border: 0;
}

.faqSlider .accordion .accordion-item:first-of-type .accordion-header {
  border-top: 1px solid #d9d9d9;
}

.faqSlider .accordion-flush .accordion-item:last-child {
  border-bottom: 1px solid #d9d9d9;
}

.faqSlider .accordion-button {
  background-color: var(--white);
  border: none;
  padding: 32px 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 1.08px;
  text-transform: uppercase;
  color: var(--dark);
}

.faqSlider .accordion-button:focus {
  box-shadow: none;
}

.faqSlider .accordion-button::after {
  background-image: url(../../assets//icons/minus-bold.svg);
}

.faqSlider .collapsed::after {
  background-image: url(../../assets//icons/plus-bold.svg);
}

.faqSlider .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.faqSlider .accordion-body {
  text-align: justify;
  font-size: 13px;
  line-height: 28px;
  text-transform: capitalize;
  padding-bottom: 30px;
}

@media screen and (max-width: 478px) {
  .looksModal .modalCta p {
    font-size: 14px;
  }
}

.launchTimer {
  background-image: url(../../assets/images/timerBg.png);
  height: 140px;
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  font-family: var(--font-family);
}

.launchTimer h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 0;
  text-align: center;
}

.launchTimer .countdownClock {
  display: flex;
  gap: 17px;
}

/* LEENGUYEN TIMER */

.flip-clock {
  --fcc-digit-block-width: 30px;
  /* digit card's width */
  --fcc-digit-block-height: 44px;
  /* digit card's height */
  --fcc-digit-font-size: 18px;
  /* font size of digit */
  --fcc-digit-color: #77353b;
  /* color of digit */
  --fcc-background: var(--white);
  /* digit card's background */
  --fcc-label-color: var(--white);
  /* time label's color */
  --fcc-label-font-size: 13px;
  --fcc-divider-color: #00000020;
  /* divider's color */
}

._3cpN7 ._2EBbg:not(:last-child) {
  margin-right: 2px !important;
}

._2hRXr:after,
._2hRXr:before {
  content: '';
  width: 0 !important;
  height: var(--fcc-separator-size);
  border-radius: 50%;
  background-color: var(--fcc-separator-color);
}

._2aOn7 {
  font-weight: 500 !important;
}

._1GZQ7 {
  border-bottom: none !important;
  box-shadow: 0 0 0 0.5px #00000020;
}

@media screen and (max-width: 992px) {
  .launchTimer h1 {
    font-size: 15px;
    line-height: 26px;
    padding: 0 20px;
  }

  .launchTimer h1 b {
    font-size: 20px;
  }

  .timerValuesWrapper span {
    font-size: 16px;
    line-height: 20px;
  }

  .timerValuesWrapper label {
    font-size: 12px;
    line-height: 16px;
  }
}